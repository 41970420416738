import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { PUBLIC_URL } from "../../shared";
import { CardTypes, LocationCard, LocationCardProps } from "../../shared/components/LocationCard";
import { SpaceY } from "../../shared/components/Utils";
import { cityIdState, cityDataState} from "../../App";

const fakeLocations: LocationCardProps[] = [
  {
    city: "New York",
    image: `${PUBLIC_URL}/fake/destination1.png`,
    location: "Golden gate bridge",
    type: CardTypes.CITY_LOCATION,
    cityId: 1,
  },
  {
    city: "San Francisco",
    image: `${PUBLIC_URL}/fake/destination2.png`,
    location: "Hollywood",
    type: CardTypes.CITY_LOCATION,
    cityId: 36,
  },
];

export const LandingView = () => {
  const [cityId, setCityId] = cityIdState.useState();
  const navigate = useNavigate();

  const handleNavigate = (cityId: any, cityName: any) => {
    // set city data based selected item
    setCityId(cityId);
    localStorage.setItem("cityId", String(cityId));
    localStorage.setItem("cityName", cityName);

    // navigate to main page
    navigate("/main");
  };

  return (
    <div className="min-h-[600px] flex flex-col items-center pt-[5vh] pb-[20vh]">
      <SpaceY /> <SpaceY />
      <span className="font-medium font-poppins text-dark">Top US Cities</span>
      <SpaceY />
      <span className="text-2xl font-bold font-volkhov text-dark">Destinations</span>
      <SpaceY /> <SpaceY />
      <div className="flex flex-wrap justify-around w-full gap-y-10 gap-x-5 px-[5rem]">
        {fakeLocations.map((item) => (
          <LocationCard {...item} handleNavigate={() => handleNavigate(item.cityId, item.city)} />
        ))}
      </div>
    </div>
  );
};
