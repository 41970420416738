import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import jwtDecode from "jwt-decode";

import { PackageBuyDetail } from "./components/PackageBuyDetail";
import { MainButton } from "../../shared/components/Buttons";
import { useGetContents, useGetTickets } from "../../shared/hooks";
import { menuState, cityIdState } from "../../App";
import { convertLink } from "../../shared/components/Utils";

export const NYExploreView = () => {
  const [displayFilter, setDisplayFilter] = useState(false);
  const [menu] = menuState.useState();
  const [cityId] = cityIdState.useState();
  const navigate = useNavigate();

  const checkTokenExpiration = () => {
    const token = localStorage.getItem("authToken");

    if (token) {
      const decodedToken: any = jwtDecode(token);

      const currentTime = Date.now() / 1000; // Convert current time to seconds

      if (decodedToken.exp < currentTime) {
        // Token has expired, force logout here
        // For example, clear the token from local storage and redirect the user to the login page
        localStorage.removeItem("authToken");
        localStorage.removeItem("loginData");
        localStorage.removeItem("order_number");
        localStorage.removeItem("useremail");

        navigate("/");
      }
    }
  };

  useEffect(() => {
    const intervalId = setInterval(checkTokenExpiration, 1000); // Check token expiration every second
    return () => clearInterval(intervalId); // Clear the interval when the component unmounts
  }, []);

  const { contents } = useGetContents({
    content_id: cityId === 1 ? 160 : 215,
    menu,
  });
  return (
    <div className="flex flex-col w-full gap-x-8 md:flex-row">
      <div className="flex flex-col w-full md:w-2/3 gap-y-8">
        <div
          dangerouslySetInnerHTML={{
            __html: convertLink(contents?.ticket_content.content || ""),
          }}
        ></div>
        {/* <MainButton
          text={"티켓구입"}
          containerClassName="w-full block md:hidden"
          onClick={() => setDisplayFilter((prev) => !prev)}
        /> */}
      </div>
      <div className="w-full mt-8 md:w-1/3 md:block font-poppins md:mt-0">
        <PackageBuyDetail
          tickets={[]}
          singleTicket={contents}
          categoryId={Number(menu[0]?.dropdownElements?.[2].category_id)}
          subCategoryId={Number(menu[0]?.dropdownElements?.[2].id)}
          subCategoryTitle={"NY City Explore Pass"}
          subPath={"ny-explore-pass"}
        />
      </div>
    </div>
  );
};
