import { useEffect, useState } from "react";
import { useNavigate, unstable_usePrompt } from "react-router-dom";
import { toast } from "react-toastify";
import DeleteForeverOutlinedIcon from '@material-ui/icons/DeleteForeverOutlined';
import jwtDecode from "jwt-decode";

import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

import { SpaceY } from "../../shared/components/Utils";
import { CartItem, cartState, reservationsState, cityIdState, cityDataState } from "../../App";
import Checkout from './Checkout'
import { staticFiles } from "../../shared";

type cartData = {
  adultInfo: CartItem[];
  childInfo: CartItem[];
};

const stripeTestPromise = loadStripe(`pk_test_51NXXnoFiJUoJo4YUdGLKaPwGOD0GU5bvut6NJPONPhcVMaieF2q1LvRM6JmvbGwuUJunuNUmTjgG1cdUrwb2DOOR00lOkeErhO`);

const getSubTotal = (
  ticket: CartItem,
  map: Map<any, any>,
  individualTicketsMap: any[]
) => {
  let totalPrice = 0;
  let key = ticket?.cartId;
  let subCategoryId = ticket?.subCategoryId;
  if (key && subCategoryId) {
    let existingTickets = map.get(key) || [];
    if (existingTickets.length === 0) {
      totalPrice += Number(ticket?.subtotal) == -100 ? -(Number(ticket?.subCategoryPrice) + Number(ticket?.addition)) *
        Number(ticket?.quantity) :
        (Number(ticket?.subCategoryPrice) + Number(ticket?.addition)) *
        Number(ticket?.quantity);
    } else {
      totalPrice += Number(ticket?.subtotal) == -100 ? -Number(ticket?.addition) * Number(ticket?.quantity) : Number(ticket?.addition) * Number(ticket?.quantity);
    }
    map.set(key, existingTickets.concat(ticket));
  } else {
    individualTicketsMap.push(ticket);
    totalPrice += Number(ticket?.subtotal) == -100 ? -(Number(ticket?.price) + Number(ticket?.addition)) *
      Number(ticket?.quantity) :
      (Number(ticket?.price) + Number(ticket?.addition)) *
      Number(ticket?.quantity);
  }

  return totalPrice;
};

const parseCartData = (data: cartData) => {
  const adultSubCategoriesMap = new Map();
  const childSubCategoriesMap = new Map();

  const adultIndividualTicketsMap: any[] = [];
  const childIndividualTicketsMap: any[] = [];

  let totalPrice = 0;

  data?.adultInfo?.forEach((ticket: CartItem) => {
    totalPrice += getSubTotal(
      ticket,
      adultSubCategoriesMap,
      adultIndividualTicketsMap
    );
  });

  data?.childInfo?.forEach((ticket: CartItem) => {
    totalPrice += getSubTotal(
      ticket,
      childSubCategoriesMap,
      childIndividualTicketsMap
    );
  });

  return {
    adultSubCategoriesMap: Array.from(adultSubCategoriesMap),
    childSubCategoriesMap: Array.from(childSubCategoriesMap),
    adultIndividualTicketsMap,
    childIndividualTicketsMap,
    totalPrice,
  };
};

const TicketCartView = ({
  tickets,
  adult_child_type,
  handleDeleteItem,
  navigate,
  handleEdit,
  isBookingEdit
}: any) => {
  const [cityId, setCityId] = cityIdState.useState();
  const [cityData, setCityData] = cityDataState.useState();

  return tickets?.map((ticket: any, i: number) => {
    let item = {
      reservation_id: ticket.reservation_id,
      item_id: ticket.item_id,
      subcategory_id: ticket.subCategoryId,
      ticket_id: ticket.ticket_id,
      quantity: ticket.quantity,
      price: ticket.price,
      adult_child_type: ticket.adult_child_type,
      subtotal: ticket.subtotal
    };

    return (
      <div key={i}>
        <div className="font-poppins text-darkGray">
          <div className="flex grid items-center justify-center grid-cols-6 md:grid-cols-12 p-1 text-xs sm:text-sm text-center border-2 border-dashed border-gray mb-4">
            <button
              className="col-span-1 md:col-span-2 text-left md:underline text-sky-700 text-xs sm:text-sm"
              onClick={() => navigate(`/product-detail/${ticket?.ticket_id}`)}
            >
              {ticket?.name || "Ticket Name Not Defined"}
            </button>
            <div>{ ticket?.ticket_type === "SIM card" ? "" : adult_child_type}</div>
            <div className="col-span-2 hidden md:block">
              {/* if the card type is simcard or hardcopy */}
              {ticket?.tour_date ? ticket?.tour_date : ticket?.ticket_type === "Hard copy" || ticket?.ticket_type === "SIM card" ? "" : "TBD"}
            </div>
            <div className="hidden md:block">${Number(ticket?.price)}</div>
            <div >{Number(ticket?.quantity)}</div>
            <div className="hidden md:block">${Number(ticket?.addition)}</div>
            <div>
              $
              {(Number(ticket?.price) + Number(ticket?.addition)) *
                Number(ticket?.quantity)}
            </div>
            <div className="hidden md:flex">
              {(ticket.reservation_id != null && Number(ticket.subtotal) != -100) ? 'Booking Update' : ''}
            </div>
            <div className="w-[16px] lg:w-[30%] xl:w-[20%] hidden"> {(ticket.reservation_id != null && Number(ticket.subtotal) != -100) ?
              <img
                width={500}
                src={staticFiles.images.restart_icon}
                className="relative object-contain ml-5"
              />
              : ''}
            </div>
            {(ticket.reservation_id != null && Number(ticket.subtotal) != -100) && <button
              className="underline text-sky-700 "
              onClick={() => {
                handleEdit();
                if (isBookingEdit) {
                  navigate(
                    `/product-detail/${ticket?.ticket_id}?edit=${true}&cartId=${ticket.cartId
                    }&type=cart+booking`, { state: item }
                  );
                } else {
                  navigate(
                    `/product-detail/${ticket?.ticket_id}?edit=${true}&cartId=${ticket.cartId
                    }&type=cart`
                  );
                }
              }
              }
            >
              Edit
            </button>}
            {(ticket.reservation_id == null) && <button
              className="underline text-sky-700 flex justify-end md:justify-center md:flex-none"
              onClick={() => {
                handleEdit();
                if (isBookingEdit) {
                  navigate(
                    `/product-detail/${ticket?.ticket_id}?edit=${true}&cartId=${ticket.cartId
                    }&type=cart+booking`, { state: item }
                  );
                } else {
                  let cityName = cityData.cityData.find((it: any) => it.id == Number(ticket?.cityId))?.name;
                  localStorage.setItem("cityId", String(cityId));
                  localStorage.setItem("cityName", String(cityName));
                  setCityId(Number(ticket?.cityId));
                  if (ticket?.subCategoryName === "explore-pass" || ticket?.subCategoryName === "city-pass") {
                    navigate(
                      `/package-tour/${ticket?.subCategoryName}?edit=${true}&cartId=${ticket.cartId
                      }&type=cart`
                    );
                  } else {
                    navigate(
                      `/product-detail/${ticket?.ticket_id}?edit=${true}&cartId=${ticket.cartId
                      }&type=cart`
                    );
                  }
                }
              }
              }
            >
              Edit
            </button>}
            {(ticket.reservation_id != null && Number(ticket.subtotal) != -100) && <button
              onClick={() => handleDeleteItem(ticket?.cartId, adult_child_type)}
              className="flex items-center justify-center text-orange-700 underline flex justify-end md:justify-center md:flex-none "
            >
              <div className="w-[14px] lg:w-16px">
                <img
                  width={500}
                  src={staticFiles.images.delete_icon}
                  className="relative object-contain"
                />
              </div>
            </button>}
            {(ticket.reservation_id == null) && <button
              onClick={() => handleDeleteItem(ticket?.cartId, adult_child_type)}
              className="flex items-center text-orange-700 underline flex justify-end md:justify-center md:flex-none"
            >
              <div className="w-[14px] lg:w-16px">
                <img
                  width={500}
                  src={staticFiles.images.delete_icon}
                  className="relative object-contain"
                />
              </div>
            </button>}
          </div>
        </div>
      </div>
    );
  });
};

const SubCategoryCartView = ({
  subCategories,
  adult_child_type,
  handleDeleteItem,
  navigate,
  handleEdit,
  isBookingEdit
}: any) => {
  return subCategories?.map((subCategory: any, i: number) => {
    const subCategorySummary = {
      title:
        subCategory[1][0]?.priceOptionTitle ||
        subCategory[1][0]?.subCategoryTitle,
      price: Number(subCategory[1][0]?.subCategoryPrice),
      quantity: Number(subCategory[1][0]?.quantity),
      addition: 0,
      subTotal: 0,
      subPath: "",
      cartId: "",
      type: "",
    };
    subCategory[1]?.forEach((ticket: any) => {
      subCategorySummary.addition +=
        Number(ticket?.addition) * Number(ticket?.quantity);
    });
    subCategorySummary.subTotal =
      subCategorySummary.price * subCategorySummary.quantity +
      subCategorySummary.addition;

    subCategorySummary.subPath = subCategory[1][0].subPath;
    subCategorySummary.cartId = subCategory[1][0].cartId;
    subCategorySummary.type = (subCategory[1][0].reservation_id != null && Number(subCategory[1][0].subtotal) != -100) ? "Booking Update" : "";

    let item = {
      reservation_id: subCategory[1][0].reservation_id,
      item_id: subCategory[1][0].item_id,
      subcategory_id: subCategory[1][0].subCategoryId,
      tickets: subCategory[1].map((it: any) => { return { ticket_id: it.ticket_id.split("+")[0], subitem_id: it.ticket_id.split("+")[1], ticket_type: it.ticket_type, ticket_sent_status: it.ticket_sent_status } }),
      quantity: subCategory[1][0].quantity,
      price: subCategory[1][0].price,
      adult_child_type: subCategory[1][0].adult_child_type,
      subtotal: subCategory[1][0].subtotal
    };

    return (
      <div className="mb-4" key={i}>
         <div className="text-xs sm:text-sm text-center font-poppins text-darkGray">
          <div className="grid grid-cols-6 md:grid-cols-12 p-1 mt-5 border-2 border-dashed border-gray">
            <div className="col-span-1 md:col-span-2 text-left text-blue">
              {subCategorySummary?.title}
            </div>
            <div>{adult_child_type}</div>
            <div className="col-span-3 md:col-span-2 hidden md:block"></div>
            <div className="hidden md:block">${subCategorySummary?.price}</div>
            <div>{subCategorySummary?.quantity}</div>
            <div className="hidden md:block">${subCategorySummary?.addition.toFixed(2)}</div>
            <div>${subCategorySummary?.subTotal.toFixed(2)}</div>
            <div className="hidden md:flex">
              {subCategorySummary?.type}
            </div>
            <div className="w-[16px] block md:hidden flex items-center justify-center hidden"> {(subCategory[1][0].reservation_id !== null && Number(subCategory[1][0].subtotal) !== -100) ?
              <img
                alt=""
                width={500}
                src={staticFiles.images.restart_icon}
                className="relative object-contain ml-5"
              />
              : ''}
            </div>
            {(subCategory[1][0].reservation_id !== null && Number(subCategory[1][0].subtotal) !== -100) && <button
              onClick={() => {
                handleEdit();
                if (isBookingEdit) {
                  navigate(
                    `/package-tour/${subCategorySummary.subPath
                    }?edit=${true}&cartId=${subCategorySummary.cartId}&type=cart+booking`, { state: item }
                  );
                } else {
                  navigate(
                    `/package-tour/${subCategorySummary.subPath
                    }?edit=${true}&cartId=${subCategorySummary.cartId}&type=cart`
                  );
                }
              }
              }
              className="underline text-sky-700 flex"
            >
              Edit
            </button>}
            {(subCategory[1][0].reservation_id == null) && <button
              onClick={() => {
                handleEdit();
                if (isBookingEdit) {
                  navigate(
                    `/package-tour/${subCategorySummary.subPath
                    }?edit=${true}&cartId=${subCategorySummary.cartId}&type=cart+booking`, { state: item }
                  );
                } else {
                  navigate(
                    `/package-tour/${subCategorySummary.subPath
                    }?edit=${true}&cartId=${subCategorySummary.cartId}&type=cart`
                  );
                }
              }
              }
              className="underline text-sky-700 flex justify-end md:justify-center md:flex-none"
            >
              Edit
            </button>}
            {(subCategory[1][0].reservation_id != null && Number(subCategory[1][0].subtotal) != -100) && <button
              onClick={() => handleDeleteItem(subCategory[0], adult_child_type)}
              className="flex items-center text-orange-700 underline flex justify-end md:justify-center md:flex-none"
            >
              <div className="w-[14px] lg:w-16px">
                <img
                  width={700}
                  src={staticFiles.images.delete_icon}
                  className="relative object-contain"
                />
              </div>
            </button>}
            {(subCategory[1][0].reservation_id == null) && <button
              onClick={() => handleDeleteItem(subCategory[0], adult_child_type)}
              className="flex items-center text-orange-700 underline flex justify-end md:justify-center md:flex-none"
            >
              <div className="w-[14px] lg:w-16px">
                <img
                  width={700}
                  src={staticFiles.images.delete_icon}
                  className="relative object-contain"
                />
              </div>
            </button>}
          </div>
        </div>
        <SpaceY />
        {subCategory[1]?.map((ticket: any, ti: number) => {
          return (
            <div className="font-poppins text-darkGray" key={ti}>
              <div className="flex grid items-center justify-center grid-cols-6 md:grid-cols-12 p-1 text-sm text-center">
                <button
                  className="col-span-3 md:col-span-2 text-left md:underline text-sky-700 text-xs sm:text-sm"
                  onClick={() =>
                    navigate(`/product-detail/${ticket?.ticket_id}`)
                  }
                >
                  {ticket?.name || "Ticket Name Not Defined"}
                  <div className="col-span-3 md:col-span-2 block md:hidden text-[#747474]">
                    <div className="flex flex-wrap">
                      <span className="font-medium md:hidden mr-2">Scheduled Date</span>{" "}
                      {ticket?.tour_date ? ticket?.tour_date : ticket?.ticket_type === "Hard copy" || ticket?.ticket_type === "SIM card" ? "" : "TBD"}
                    </div>
                  </div>
                </button>
                <div className="hidden md:block"></div>
                <div className="hidden"></div>
                <div className="hidden"></div>
                <div className="col-span-2 hidden md:block">
                  {/* if the card type is simcard or hardcopy */}
                  {ticket?.tour_date ? ticket?.tour_date : ticket?.ticket_type === "Hard copy" || ticket?.ticket_type === "SIM card" ? "" : "TBD"}
                </div>
                <div className="hidden md:block"></div>
                <div className="hidden md:block"></div>
                <div className="text-xs sm:text-sm self-start md:self-auto">${Number(ticket?.addition)}</div>
                <div></div>
                <div></div>
              </div>
            </div>
          );
        })}
      </div>
    );
  });
};

const authToken = localStorage.getItem("authToken");
const isMemberLoggedIn = localStorage.getItem("loginData") === "email";

export const CartView = () => {
  const [cartRawData, setCartRawData] = useState(null as cartData | null);
  const [cartRefinedData, setCartRefinedData] = useState(null as any);
  const [cart, setCart] = cartState.useState();
  const [reservationsData, setReservationsData] = reservationsState.useState();
  const [shouldBlock, setShouldBlock] = useState(false);
  const [autoLogout, setAutoLogout] = useState(false);
  const [edit, setEdit] = useState(false);

  const navigate = useNavigate();

  const checkTokenExpiration = () => {
    const token = localStorage.getItem("authToken");

    if (token) {
      const decodedToken: any = jwtDecode(token);

      const currentTime = Date.now() / 1000; // Convert current time to seconds

      if (decodedToken.exp < currentTime) {
        // Token has expired, force logout here
        // For example, clear the token from local storage and redirect the user to the login page
        localStorage.removeItem("authToken");
        localStorage.removeItem("loginData");
        localStorage.removeItem("order_number");
        localStorage.removeItem("useremail");

        setAutoLogout(true);

        navigate("/");
      }
    }
  };


  useEffect(() => {
    const intervalId = setInterval(checkTokenExpiration, 1000); // Check token expiration every second
    return () => clearInterval(intervalId); // Clear the interval when the component unmounts
  }, []);

  useEffect(() => {
    const rawData = localStorage.getItem("CART_DATA");
    if (rawData) {
      const parsedData = JSON.parse(rawData);
      console.log("*********************", parsedData, parseCartData(parsedData))
      setCartRawData(parsedData);
      setCartRefinedData(parseCartData(parsedData));
    }
  }, []);

  useEffect(() => {
    setShouldBlock(checkPendingBookingEditInShoppingCart(cart));
  }, [cart]);

  useEffect(() => {
    return () => {
      // Remove Booking Edit items in Cart
      if (shouldBlock && !autoLogout && !edit) {
        // alert(edit)
        // alert("Delete localstorage");
        // let cartData = localStorage.getItem("CART_DATA")
        //   ? JSON.parse(localStorage.getItem("CART_DATA") || "")
        //   : { adultInfo: [], childInfo: [] };


        // cartData.adultInfo = cartData.adultInfo.filter(
        //   (item: any) => item.reservation_id == null
        // );

        // cartData.childInfo = cartData.childInfo.filter(
        //   (item: any) => item.reservation_id == null
        // );

        // localStorage.setItem("CART_DATA", JSON.stringify(cartData));
        // setCart(cartData);
      }
    };
  }, [shouldBlock]);

  const handleSetEdit = () => {
    setEdit(true);
  };

  const handleDeleteItem = (
    cartId: string,
    adult_child_type: "Adult" | "Child"
  ) => {
    let cartData = localStorage.getItem("CART_DATA")
      ? JSON.parse(localStorage.getItem("CART_DATA") || "")
      : { adultInfo: [], childInfo: [] };
    let cartItem = [...cartData.adultInfo, ...cartData.childInfo].filter(
      (item: any) => item.cartId == cartId
    );

    if (adult_child_type === "Adult") {
      cartData.adultInfo = cartData.adultInfo.filter(
        (item: any) => item.cartId !== cartId
      );

    } else if (adult_child_type === "Child") {
      cartData.childInfo = cartData.childInfo.filter(
        (item: any) => item.cartId !== cartId
      );
    }

    // remove related ones when deleting booking Edit items
    let reservation_id = cartItem.length > 0 ? cartItem[0]?.reservation_id : null;

    if (reservation_id) {
      cartData.adultInfo = cartData.adultInfo.filter(
        (item: any) => item.reservation_id !== reservation_id
      );
      cartData.childInfo = cartData.childInfo.filter(
        (item: any) => item.reservation_id !== reservation_id
      );
    }

    localStorage.setItem("CART_DATA", JSON.stringify(cartData));
    setCartRawData(cartData);
    setCartRefinedData(parseCartData(cartData));
    setCart(cartData);
    toast("Success! Item removed!");
  };

  const checkPendingBookingEditInShoppingCart = ({
    childInfo,
    adultInfo,
  }: {
    childInfo: CartItem[];
    adultInfo: CartItem[];
  }) => {
    let result = false;
    const combinedInfo = [...childInfo, ...adultInfo];

    for (let info of combinedInfo) {
      if (info.reservation_id) {
        result = true;
        break;
      }
    };

    return result;
  };




  return (
    <Elements stripe={stripeTestPromise}>
      <main className="flex flex-col items-center w-full">
        <div className="bg-[#F2F2F2] w-[100vw] min-h-[600px] pb-[10vh] pt-[10vh] flex justify-center px-4">
          <div className="flex flex-col gap-x-4 w-full xl:flex-row">
            <div className="flex flex-col w-full bg-white p-4">
              <div className="w-full  md:justify-between">
                <span className="font-normal font-poppins text-darkGray">
                  Shopping Cart
                </span>
              </div>
              <SpaceY />
              <hr className="hidden w-full border rounded md:block border-gray" />
              <div className="font-poppins text-darkGray hidden md:block">
                <div className="grid grid-cols-12 p-1 text-sm font-normal text-center">
                  <div className="flex items-center justify-center col-span-2 lg:block vertical-lr">상품</div>
                  <div className="col-span-1 flex items-center justify-center lg:block vertical-lr">Adult/Child</div>
                  <div className="col-span-2 flex items-center justify-center lg:block vertical-lr">Scheduled Date</div>
                  <div className="flex items-center justify-center lg:block vertical-lr">Price</div>
                  <div className="flex items-center justify-center lg:block vertical-lr">Quantity</div>
                  <div className="flex items-center justify-center lg:block vertical-lr">Addition</div>
                  <div className="flex items-center justify-center lg:block vertical-lr">Subtotal</div>
                  <div className="flex items-center justify-center lg:block vertical-lr">Type</div>
                  <div></div>
                </div>
              </div>
              <hr className="hidden w-full border rounded md:block border-gray" />
              <div>
                <SpaceY />
                <SubCategoryCartView
                  subCategories={cartRefinedData?.adultSubCategoriesMap || []}
                  adult_child_type={"Adult"}
                  handleDeleteItem={handleDeleteItem}
                  navigate={navigate}
                  handleEdit={handleSetEdit}
                  isBookingEdit={shouldBlock}
                />
                <SubCategoryCartView
                  subCategories={cartRefinedData?.childSubCategoriesMap || []}
                  adult_child_type={"Child"}
                  handleDeleteItem={handleDeleteItem}
                  navigate={navigate}
                  handleEdit={handleSetEdit}
                  isBookingEdit={shouldBlock}
                />
                {/* <SpaceY />
                <SpaceY /> */}
                <TicketCartView
                  tickets={cartRefinedData?.adultIndividualTicketsMap || []}
                  adult_child_type={"Adult"}
                  handleDeleteItem={handleDeleteItem}
                  navigate={navigate}
                  handleEdit={handleSetEdit}
                  isBookingEdit={shouldBlock}
                />
                <TicketCartView
                  tickets={cartRefinedData?.childIndividualTicketsMap || []}
                  adult_child_type={"Child"}
                  handleDeleteItem={handleDeleteItem}
                  navigate={navigate}
                  handleEdit={handleSetEdit}
                  isBookingEdit={shouldBlock}
                />
              </div>
              {/* <hr className="hidden w-full my-5 border rounded md:block border-gray" /> */}
              {/* <SpaceY />
              <SpaceY /> */}
              {/* <div className="grid grid-cols-11 pr-2 text-center text-darkGray">
              <div className="col-start-9">
                Total : ${cartRefinedData?.totalPrice || 0}
              </div>
            </div> */}
              {(cartRawData?.adultInfo && cartRawData.adultInfo.length > 0 || cartRawData?.childInfo && cartRawData?.childInfo?.length > 0) && cartRefinedData?.totalPrice == 0 &&
                <div>
                <div className="flex flex-col items-center w-full mt-10 md:flex">
                <p className="text-blue">You have no balance to pay and the change will be updated in My Bookings.</p>
              </div>
                <div className="flex flex-col items-center w-full mt-10 md:flex">
                  <button
                    className="w-1/4 px-4 py-2 font-medium text-white bg-blue"
                    onClick={() => {
                      if (authToken && isMemberLoggedIn) {
                        navigate("/my-bookings");
                      } else {
                        navigate("/no-auth-checkout");
                      }
                    }}
                  >
                    Go to My Bookings
                  </button>
                </div></div>}
            </div>
            {(cartRefinedData?.totalPrice != 0 && cartRefinedData?.totalPrice) && <div><Checkout totalPrice={cartRefinedData?.totalPrice || 0} /></div>}
          </div>
        </div>
      </main>
    </Elements>
  );
};
