import { useState, useEffect, useMemo, useCallback, memo, CSSProperties } from "react";
import ClipLoader from "react-spinners/ClipLoader";
import { useNavigate, useLocation } from "react-router-dom";
import jwtDecode from "jwt-decode";
import Swal from "sweetalert2";
import axios from "axios";
import download from "downloadjs";
import { toast } from "react-toastify";

import { staticFiles } from "../../shared";
import { useGetReservations, updateScheduleOptions } from "../../shared/hooks";
import { API } from "../../shared";
import { CartItem, cartState, reservationsState, reservationsParsedState, cityDataState, cityIdState } from "../../App";
import { Modal } from "./Modal";
import { ModalSpinner } from "./ModalSpinner";
import "./styles.css";
import { formatDateTime } from "../../shared/components/Utils";

const override: CSSProperties = {
  display: "block",
  margin: "0 auto",
  borderColor: "red",
};

// Convert 2023-08-30 13:00:00 to ISO8601 format
function convertToISO8601(dateString: string) {
  // Parse date and time from the string
  const [date, time] = dateString.split(" ");
  const [year, month, day] = date.split("-");
  const [hour, minute, second] = time.split(":");

  let dateInUTC = new Date(
    Date.UTC(
      parseInt(year),
      parseInt(month) - 1, // Month is 0-based in JavaScript
      parseInt(day),
      parseInt(hour),
      parseInt(minute),
      parseInt(second)
    )
  );

  let isoDate = dateInUTC.toISOString().split(".000Z")[0];
  return isoDate;
}

const ScheduledDateComponent = ({
  input,
  orderNumber,
  isMultipe,
  subitem_id,
  options_schedules,
  item_quantity,
  showModalProp,
  handleShow,
  modalForm,
  handleChange,
  handleSubmit,
  isSubmitted,
  ticketName,
  ticketType,
}: {
  input: any;
  orderNumber: string;
  isMultipe: boolean;
  subitem_id: number;
  options_schedules: any;
  item_quantity: number;
  showModalProp: any;
  handleShow: any;
  modalForm: any;
  handleChange: any;
  handleSubmit: any;
  isSubmitted: any;
  ticketName: string;
  ticketType: string;
}) => {
  const [options, setOptions] = useState<any>([]);
  const [allowMultipe, setAllowMultipe] = useState(false);
  //setcurrentticket_id
  const [showThankModal, setShowThankModal] = useState(false);
  const [IsSubmitted, setIsSubmitted] = useState(false);
  const [submitDone, setSubmitDone] = useState(false);
  const [topping, setTopping] = useState("");
  const [firstValueToRender, setFirstValueToRender] = useState("");
  const [secondValueToRender, setSecondValueToRender] = useState("");
  const [thirdValueToRender, setThirdValueToRender] = useState("");
  const [firstDate, setFirstDate] = useState("");
  const [secondDate, setSecondDate] = useState("");
  const [thirdDate, setThirdDate] = useState("");
  const [isDisabled, setIsDisabled] = useState(false);
  const [isSubmitShow, setIsSubmitShow] = useState(false);
  let schedule_options = modalForm[String(subitem_id)];

  // if (options_schedules?.length === 3 && !input) {
  //   firstValueToRender = options_schedules[0]?.datetime;
  //   secondValueToRender = options_schedules[1]?.datetime;
  //   thirdValueToRender = options_schedules[2]?.datetime;

  //   isDisabled = true;
  // }
  useEffect(() => {
    schedule_options = modalForm[String(subitem_id)];
    let Disabled = schedule_options?.reduce((acc: any, item: any) => {
      return acc && typeof item === "string";
    }, true);
    setIsDisabled(Disabled);
    setFirstValueToRender(schedule_options && schedule_options[0]);
    setSecondValueToRender(schedule_options && schedule_options[1]);
    setThirdValueToRender(schedule_options && schedule_options[2]);
  }, []);

  useEffect(() => {
    if (firstDate !== "" && secondDate !== "" && thirdDate !== "") {
      setIsSubmitShow(true);
    }
  }, [firstDate, secondDate, thirdDate]);

  useEffect(() => {
    if (isSubmitted && submitDone) {
      schedule_options = modalForm[String(subitem_id)];
      if (schedule_options?.length === 3 && !input) {
        setFirstValueToRender(schedule_options[0]?.datetime);
        setSecondValueToRender(schedule_options[1]?.datetime);
        setThirdValueToRender(schedule_options[2]?.datetime);

        setIsDisabled(true);
      }
      setOptions(schedule_options);
    } else {
      schedule_options = modalForm[String(subitem_id)];

      if (!schedule_options) {
        if (options_schedules?.length === 3 && !input) {
          setFirstValueToRender(options_schedules[0]?.datetime);
          setSecondValueToRender(options_schedules[1]?.datetime);
          setThirdValueToRender(options_schedules[2]?.datetime);

          setIsDisabled(true);
        }
        setOptions(options_schedules);
      } else {
        if (schedule_options?.length === 3 && !input) {
          setFirstValueToRender(schedule_options[0]);
          setSecondValueToRender(schedule_options[1]);
          setThirdValueToRender(schedule_options[2]);

          setIsDisabled(true);
        }
        setOptions(schedule_options);
      }
    }

    if (isSubmitted) {
      setSubmitDone(false);
    }
  }, [isSubmitted, options_schedules, modalForm]);

  const onOptionChange = (e: any) => {
    if (e.target.value === "Yes") {
      setAllowMultipe(true);
    } else {
      setAllowMultipe(false);
    }
    setTopping(e.target.value);
  };

  if (!isNaN(Date.parse(input))) {
    return <div>{formatDateTime(input)}</div>;
  } else if (ticketType === "Guide Tour") {
    return <div>TBD</div>;
  } else if (ticketType === "Regular") {
    return (
      <div>
        <button
          onClick={() => {
            handleShow(subitem_id);
          }}
          className="flex justify-center w-full items-center"
        >
          {options?.length === 0 && (
            <>
              <img alt="" className="cursor-pointer" src={staticFiles.icons.card_calendar} />{" "}
              <span className="pl-1 sm:pl-3 text-[#0D9323]">Schedule</span>
            </>
          )}
          {options?.length === 3 && !input && <span className="font-poppins underline cursor-pointer text-blue">Requested</span>}
          {input && <span className="px-3 font-poppins">{input}</span>}
        </button>
        {showModalProp === subitem_id && !isSubmitted && (
          <Modal
            onClose={() => {
              // close actions here
            }}
            disabled={isDisabled}
          >
            <form
              className="flex flex-col gap-4"
              onSubmit={(e) => {
                handleSubmit(e, subitem_id, orderNumber, ticketName, allowMultipe);
                setTopping("");
                setIsSubmitted(false);
                setShowThankModal(true);
                setSubmitDone(true);
                handleShow(-100);
              }}
            >
              {options?.length === 3 && !input && <div className="text-xl font-medium text-center  text-[#5D5D5F] ">예약 요청 확인</div>}
              {options?.length === 0 && isMultipe && !IsSubmitted && !isSubmitShow && (
                <div className=" text-xl font-medium text-center  text-[#5D5D5F]">예약 요청</div>
              )}
              {options?.length === 0 && isMultipe && isSubmitShow && !IsSubmitted && (
                <div className=" text-xl font-medium text-center  text-[#5D5D5F]">에약 요청</div>
              )}
              {options?.length === 0 && isMultipe && IsSubmitted && (
                <div className=" text-xl font-medium text-center  text-[#5D5D5F]">예약 요청 확인</div>
              )}
              {options?.length === 0 && !isMultipe && !IsSubmitted && !isSubmitShow && (
                <div className=" text-xl font-medium text-center  text-[#5D5D5F]">예약 요청</div>
              )}
              {options?.length === 0 && !isMultipe && isSubmitShow && !IsSubmitted && (
                <div className=" text-xl font-medium text-center  text-[#5D5D5F]">예약 요청</div>
              )}
              {options?.length === 0 && !isMultipe && IsSubmitted && (
                <div className=" text-xl font-medium text-center  text-[#5D5D5F]">예약 요청 확인</div>
              )}

              <div className="">
                <span className="font-bold text-[#5D5D5F]">Ticket Name:</span> <span className="font-normal text-[#5D5D5F]">{ticketName}</span>
              </div>
              {options?.length === 0 && isMultipe && (topping === "Yes" || topping === "") && (
                <div className=" text-sm font-normal text-justify">
                  You have purchased multiple tickets for Oder Number: {orderNumber}. Would you like to book the same date?
                </div>
              )}
              {options?.length === 0 && isMultipe && !isSubmitShow && (
                <div className="flex justify-between">
                  <div className="flex justify-between mr-3 grow font-poppins">
                    <div>
                      <input
                        className="mx-3 accent-green-600"
                        type="radio"
                        name="topping"
                        value="Yes"
                        id="Yes"
                        checked={topping === "Yes"}
                        onChange={onOptionChange}
                      />
                      <span className="cursor-pointer">Yes</span>
                    </div>
                    <div>
                      <input
                        className="mx-3 accent-green-600"
                        type="radio"
                        name="topping"
                        value="No"
                        id="No"
                        checked={topping === "No"}
                        onChange={onOptionChange}
                      />
                      <span className="cursor-pointer">No</span>
                    </div>
                  </div>
                  <div className="mx-3">
                    <img alt="" className="cursor-pointer" src={staticFiles.icons.help_circle} />
                  </div>
                </div>
              )}
              {(topping === "Yes" || topping === "No" || !isMultipe || input) && (
                <div className="text-sm">
                  <div className="mb-4">
                    <div>First Choice</div>
                    <input
                      type="datetime-local"
                      className="w-full p-1 border cursor-pointer"
                      name="first_option"
                      disabled={isDisabled}
                      onChange={(e) => {
                        handleChange(e, subitem_id, 0);
                        setFirstDate(e.target.value);
                      }}
                      value={firstValueToRender}
                    />
                  </div>

                  <div className="mb-4">
                    <div>Second Choice</div>
                    <input
                      type="datetime-local"
                      className="w-full p-1 border cursor-pointer"
                      name="second_option"
                      disabled={isDisabled}
                      onChange={(e) => {
                        handleChange(e, subitem_id, 1);
                        setSecondDate(e.target.value);
                      }}
                      value={secondValueToRender}
                    />
                  </div>

                  <div className="mb-4">
                    <div>Third Choice</div>
                    <input
                      type="datetime-local"
                      className="w-full p-1 border cursor-pointer"
                      name="third_option"
                      disabled={isDisabled}
                      onChange={(e) => {
                        handleChange(e, subitem_id, 2);
                        setThirdDate(e.target.value);
                      }}
                      value={thirdValueToRender}
                    />
                  </div>
                </div>
              )}
              {!isDisabled && !IsSubmitted && isSubmitShow && (
                <div>
                  <button
                    className={"text-medium border text-white rounded px-2 py-2 w-1/2 bg-blue"}
                    type="button"
                    onClick={() => {
                      setIsSubmitted(true);
                    }}
                  >
                    Submit
                  </button>
                  <button className="w-1/2 px-2 py-2 text-white border rounded text-medium bg-gray " type="reset">
                    Reset
                  </button>
                </div>
              )}
              {!isDisabled && IsSubmitted && (
                <>
                  <button className={"text-medium border text-white rounded px-2 py-2 w-1/2 bg-blue"} type="submit">
                    Confirm
                  </button>
                  <button
                    className="w-1/2 px-2 py-2 text-white border rounded text-medium bg-gray"
                    type="button"
                    onClick={() => {
                      handleShow(-100);
                      setTopping("");
                      setIsSubmitted(false);
                      setShowThankModal(false);
                      setIsSubmitShow(false);
                      setFirstDate("");
                      setSecondDate("");
                      setThirdDate("");
                    }}
                  >
                    Cancel
                  </button>
                </>
              )}

              <div className="absolute text-lg font-medium top-2 right-2 ">
                <button
                  onClick={() => {
                    handleShow(-100);
                    setTopping("");
                    setIsSubmitted(false);
                    setShowThankModal(false);
                    setIsSubmitShow(false);
                    setFirstDate("");
                    setSecondDate("");
                    setThirdDate("");
                  }}
                >
                  <img alt="" className="cursor-pointer" src={staticFiles.icons.remove} />
                </button>
              </div>
            </form>
          </Modal>
        )}
        {/* // Loading Spinner  */}
        {!isSubmitted && submitDone && (
          <ModalSpinner
            onClose={() => {
              // close actions here
            }}
          >
            <div style={{ width: "100px", margin: "auto", display: "block" }}>
              <img alt="" src={staticFiles.icons.schedule_optionconfirm} style={{ position: "absolute", marginLeft: "10px", top: "55px" }} />
              <ClipLoader color="#52bfd9" size={120} />
            </div>
          </ModalSpinner>
        )}

        {isSubmitted && (
          <Modal
            onClose={() => {
              // close actions here
            }}
          >
            <div
              className="font-poppins"
              // onSubmit={(e) => handleSubmit(e, subitem_id)}
            >
              <div className="mb-8">
                <img alt="" className="cursor-pointer" src={staticFiles.icons.schedule_optionconfirm} />
              </div>
              <div className="mb-16 text-xl font-medium text-center text-[#5D5D5F]">Ticketing in Progress</div>
              <div className="mb-24 font-normal text-[#5D5D5F]">
                You will be receiving the ticket via Email when it’s ready. If you have any questions, please contact service@tamice.com.
                {/* Ticket Name: <span className="font-bold">{ticketName}</span> */}
              </div>
              {/* <div className="mb-6 text-sm">감사합니다.</div> */}
              <button
                className="w-full px-2 py-2 text-white border rounded text-medium bg-blue"
                onClick={() => {
                  setShowThankModal(false);
                  setSubmitDone(false);
                  setIsSubmitShow(false);
                  handleShow(-100);
                }}
              >
                Close
              </button>
              <div className="absolute text-lg font-medium top-2 right-2 ">
                <button
                  onClick={() => {
                    setShowThankModal(false);
                    setSubmitDone(false);
                    setIsSubmitShow(false);
                    handleShow(-100);
                  }}
                >
                  <img alt="" className="cursor-pointer" src={staticFiles.icons.remove} />
                </button>
              </div>
            </div>
          </Modal>
        )}
      </div>
    );
  } else if (ticketType === "Bar/QR code") {
    return <div>Anytime</div>;
  } else if (ticketType === "Hard copy" || ticketType === "SIM card") {
    return <div></div>;
  } else {
    return <div>TBD</div>;
  }
};

const checkItemsInShoppingCart = ({ childInfo, adultInfo }: { childInfo: CartItem[]; adultInfo: CartItem[] }) => {
  const combinedInfo = [...childInfo, ...adultInfo];

  if (combinedInfo.length === 0) {
    return false;
  } else if (combinedInfo.length > 0 && combinedInfo[0].reservation_id != null) {
    return false;
  } else {
    return true;
  }
};

const subPathWithAbbreviation = [
  { abb: "NY", name: "New York" },
  { abb: "SF", name: "San Francisco" },
  { abb: "LA", name: "Los Angeles" },
];

const subPathFromSubCategoryName = [
  { name: "NY Big Apple Pass", subPath: "ba-pass" },
  { name: "NY City Pass", subPath: "city-pass" },
  { name: "NY City Explore Pass", subPath: "explore-pass" },
  { name: "SF Big Apple Pass", subPath: "ba-pass" },
  { name: "SF City Pass", subPath: "city-pass" },
  { name: "SF City Explore Pass", subPath: "explore-pass" },

  { name: "Observation(Scenics)", subPath: "observations" },
  { name: "Museum/Gallery", subPath: "museum-gallery" },
  { name: "Rides/Cruises", subPath: "rides-cruises" },
  { name: "Activities", subPath: "activities" },

  { name: "Manhattan Day Tour", subPath: "manhattan-day" },
  { name: "Manhattan Night Tour", subPath: "manhattan-night" },
  { name: "Doson Tour", subPath: "doson-tour" },
  { name: "UN Tour", subPath: "un-tour" },
  { name: "My Bookings", subPath: "my-bookings" },
  { name: "Account", subPath: "account" },
];

const TicketViewMemo = memo(
  ({
    orderNumber,
    item,
    reservation,
    showModal,
    handleShow,
    modalForm,
    handleChange,
    handleSubmit,
    setIsDownLoadTicket,
    isSubmitted,
    hasSubcategory,
  }: any) => {
    const [cart, setCart] = cartState.useState();
    const [cityData, setCityData] = cityDataState.useState();
    const [cityId, setCityId] = cityIdState.useState();
    const [downloading, setDownloading] = useState(false);

    const navigate = useNavigate();
    const items = reservation.items;

    const DownloadTicket = (url: string) => {
      // Parent component setIsDownLoadTicket for refetching data
      setIsDownLoadTicket(true);
      setDownloading(true);
      // Make a POST request to get the file data
      axios({
        url,
        method: "POST",
        responseType: "blob", // Specify the response type as blob
      })
        .then((response) => {
          const content = response.headers["content-type"];
          // Get the current date and time
          // const currentDate = new Date();
          // const formattedDate = currentDate.toISOString().replace(/[^\d]/g, '').slice(0, 14);

          // Get the current date and time
          const currentDate = new Date();

          // Extract date components
          const year = currentDate.getFullYear();
          const month = String(currentDate.getMonth() + 1).padStart(2, "0");
          const day = String(currentDate.getDate()).padStart(2, "0");

          // Extract time components
          const hours = String(currentDate.getHours()).padStart(2, "0");
          const minutes = String(currentDate.getMinutes()).padStart(2, "0");
          const seconds = String(currentDate.getSeconds()).padStart(2, "0");

          // Create the formatted date and time string
          const formattedDateTime = `${year}-${month}-${day}-${hours}-${minutes}-${seconds}`;

          // Define the prefix and file extension
          const fileNamePrefix = "ticket";
          const fileExtension = "pdf";

          // Concatenate the prefix, current date/time, and file extension
          const fileName = `${fileNamePrefix}_${formattedDateTime}`;

          download(response.data, fileName, content);
          toast.success("Downloaded successfully!");
        })
        .catch((error) => {
          if (error?.response?.status === 400 || error?.response?.status === 422) {
            toast.error("There was a problem in the inventory. Please contact the Admin for the error for code: 4848!");
          } else {
            toast.error("Something is wrong on the Server");
          }
        })
        .finally(() => {
          setDownloading(false);
          setIsDownLoadTicket(false);
        });
    };

    useEffect(() => {
      return () => {
        Swal.close();
      };
    }, []);

    return (
      <>
        {downloading && (
          <ModalSpinner
            onClose={() => {
              // close actions here
            }}
          >
            <div style={{ width: "100px", margin: "auto", display: "block" }}>
              <img alt="" src={staticFiles.icons.schedule_optionconfirm} style={{ position: "absolute", marginLeft: "10px", top: "55px" }} />
              <ClipLoader color="#52bfd9" size={120} />
            </div>
          </ModalSpinner>
        )}
        {item?.tickets?.map((ticket: any, i: number) => {
          const individualTicketBorderStyles = !hasSubcategory && "border-dashed border-2 border-gray my-5 text-xs sm:text-sm sm:font-poppins";
          let sameTicketNum = 0;
          items.forEach((item: any) => {
            item.tickets.forEach((singleTicket: any) => {
              if (ticket.ticket_id === singleTicket.ticket_id && singleTicket.options_schedules.length === 0) sameTicketNum++;
            });
          });
          return (
            <div
              key={i}
              className={`grid grid-cols-4 sm:grid-cols-7 text-center text-xs text-left sm:text-sm items-center p-1 text-[#5D5D5F] ${individualTicketBorderStyles}`}
            >
              <div className="py-2 justify-center items-center col-span-1 sm:col-span-1 text-left text-[#5D5D5F]">
                <div className=" sm:font-normal ">{!item?.hasSubcategory && item?.price_list_id ? item?.product_name : ticket?.ticket_title_kr}</div>
                <div className="flex justify-start sm:justify-center items-center sm:hidden flex-wrap">
                  {ticket?.ticket_type === "Guide Tour" && <span className="text-blue mr-2">{ticket?.ticket_sent_status}</span>}
                  {(ticket?.ticket_type === "SIM card" || ticket?.ticket_type === "Hard copy") && <span className="text-blue mr-2"> Sent</span>}
                  {ticket?.ticket_type === "Bar/QR code" && <span className="text-blue mr-2"> Download</span>}

                  <span className=" flex justify-start sm:hidden text-green-600">
                    <ScheduledDateComponent
                      input={ticket?.rq_schedule_datetime}
                      orderNumber={orderNumber}
                      isMultipe={sameTicketNum === 1 || ticket?.options_schedules.length !== 0 ? false : true}
                      subitem_id={ticket?.subitem_id}
                      options_schedules={ticket?.options_schedules}
                      item_quantity={item?.quantity}
                      showModalProp={showModal}
                      handleShow={handleShow}
                      modalForm={modalForm}
                      handleChange={handleChange}
                      handleSubmit={handleSubmit}
                      isSubmitted={isSubmitted}
                      ticketName={ticket?.ticket_title_kr}
                      ticketType={ticket?.ticket_type}
                    />
                  </span>
                </div>
                <div>{/* <span className="block sm:hidden"> Scheduled Date:</span> */}</div>
              </div>

              <div className="flex items-center justify-center items-center py-2 ">{!hasSubcategory && (ticket?.ticket_type === "SIM card" ? "" : item.adult_child_type)}</div>
              <div className="flex items-center justify-center items-center py-2 font-poppins hidden sm:block">
                <ScheduledDateComponent
                  input={ticket?.rq_schedule_datetime}
                  orderNumber={orderNumber}
                  isMultipe={sameTicketNum === 1 || ticket?.options_schedules.length !== 0 ? false : true}
                  subitem_id={ticket?.subitem_id}
                  options_schedules={ticket?.options_schedules}
                  item_quantity={item?.quantity}
                  showModalProp={showModal}
                  handleShow={handleShow}
                  modalForm={modalForm}
                  handleChange={handleChange}
                  handleSubmit={handleSubmit}
                  isSubmitted={isSubmitted}
                  ticketName={ticket?.ticket_title_kr}
                  ticketType={ticket?.ticket_type}
                />
              </div>
              <div className="flex items-center justify-center items-center py-2">{!hasSubcategory && item.quantity}</div>
              <div className="flex items-center justify-center items-center py-2 hidden sm:block">
                {ticket?.ticket_type === "Guide Tour" && <div className="flex items-center justify-center py-2"> {ticket?.ticket_sent_status} </div>}
                {(ticket?.ticket_type === "SIM card" || ticket?.ticket_type === "Hard copy") &&
                  ticket?.ticket_id !== "159" &&
                  ticket?.ticket_id !== "160" &&
                  ticket?.ticket_id !== "214" &&
                  ticket?.ticket_id !== "215" && <div className="flex items-center justify-center py-2">Sent</div>}
                {ticket?.ticket_type === "Bar/QR code" && (
                  <div className="flex items-center justify-center py-2">
                    <a
                      className="underline cursor-pointer text-sky-700"
                      onClick={() => DownloadTicket(`${API}/reservations/${item.reservation_id}/reservation-subitems/${ticket?.subitem_id}/email`)}
                    >
                      Download
                    </a>
                  </div>
                )}
              </div>
              <div className="flex items-center justify-center py-2 hidden md:block">{!hasSubcategory ? item.item_id : ticket.subitem_id}</div>
              {!hasSubcategory &&
                ((ticket?.ticket_type === "Bar/QR code" && !ticket?.pdf_path) ||
                  (ticket?.ticket_type === "Regular" &&
                    ticket?.ticket_sent_status !== "sent" &&
                    ticket?.ticket_sent_status !== "pending" &&
                    ticket?.options_schedules.length !== 3)) && (
                  <button
                    className="py-2 underline text-sky-700"
                    onClick={() => {
                      if (checkItemsInShoppingCart(cart)) {
                        Swal.fire({
                          icon: "warning",
                          title: "Oops...",
                          text: "You already have items in the shopping cart, Please process the existing items first in order to proceed the Booking Edit",
                          confirmButtonText: "OK",
                        });
                      } else {
                        if (item?.quantity > 1) {
                          Swal.fire({
                            title: "Order Edit",
                            text: `The entire quantity ${item?.quantity} will be reflected when editing. Do you like to proceed?`,
                            // icon: 'question',
                            showCancelButton: true,
                            confirmButtonText: "Yes",
                            cancelButtonText: "No",
                          }).then(async (result) => {
                            if (result.isConfirmed) {
                              navigate(`/product-detail/${ticket.ticket_id}?edit=${true}&type=booking`, { state: item });
                            } else if (result.dismiss === Swal.DismissReason.cancel) {
                              return;
                            }
                          });
                        } else {
                          navigate(`/product-detail/${ticket.ticket_id}?edit=${true}&type=booking`, { state: item });
                        }
                      }
                    }}
                  >
                    Edit
                  </button>
                )}
            </div>
          );
        })}
      </>
    );
  }
);

const handleAdultChild = (item: any) => {
  const adult_child_type = item?.adult_child_type;
  const child_age = item?.child_age;

  if (adult_child_type === "Adult") {
    return adult_child_type;
  } else if (child_age && adult_child_type === "Child") {
    return `${adult_child_type} (Age:${child_age})`;
  } else {
    return adult_child_type;
  }
};

const checkIfCanEdit = (item: any) => {
  let result = false;

  for (let ticket of item.tickets) {
    if (
      (ticket?.ticket_type === "Bar/QR code" && !ticket?.pdf_path) ||
      (ticket?.ticket_type === "Regular" &&
        ticket?.ticket_sent_status !== "sent" &&
        ticket?.ticket_sent_status !== "pending" &&
        ticket?.options_schedules.length !== 3)
    ) {
      result = true;
      break;
    }
  }

  return result;
};

const SubcategoryView = ({ item, i }: any) => {
  const [cart, setCart] = cartState.useState();
  const [cityData, setCityData] = cityDataState.useState();
  const [cityId, setCityId] = cityIdState.useState();

  const navigate = useNavigate();

  const canEdit = checkIfCanEdit(item);

  useEffect(() => {
    return () => {
      Swal.close();
    };
  }, []);

  return (
    <div
      key={i}
      className="grid grid-cols-4 sm:grid-cols-7 text-xs sm:text-sm text-left sm:text-center border-2 border-dashed border-gray p-1 text-[#5D5D5F]"
    >
      <div className="py-2 text-left">{item?.product_name}</div>
      <div className="flex items-center justify-center px-6 py-2 ">{handleAdultChild(item)}</div>
      <div className="flex items-center justify-center px-6 py-2 hidden sm:block"></div>
      <div className="flex items-center justify-center px-6 py-2">{item?.quantity}</div>
      <div className="flex items-center justify-center px-6 py-2 hidden sm:block"></div>
      <div className="flex items-center justify-center px-6 py-2 hidden sm:block">{item?.item_id}</div>
      {canEdit && (
        <button
          onClick={() => {
            if (checkItemsInShoppingCart(cart)) {
              Swal.fire({
                icon: "warning",
                title: "Oops...",
                text: "You already have items in the shopping cart, Please process the existing items first in order to proceed the Booking Edit",
                confirmButtonText: "OK",
              });
            } else {
              if (item?.quantity > 1) {
                Swal.fire({
                  title: "Order Edit",
                  text: `The entire quantity ${item?.quantity} will be reflected when editing. Do you like to proceed?`,
                  // icon: 'question',
                  showCancelButton: true,
                  confirmButtonText: "Yes",
                  cancelButtonText: "No",
                }).then(async (result) => {
                  if (result.isConfirmed) {
                    let cityName = subPathWithAbbreviation.find((it: any) => it.abb == item?.subcategory_name.split(" ")[0])?.name;
                    let cityId = cityData.cityData.find((it: any) => it.name == cityName)?.id;
                    setCityId(cityId);
                    localStorage.setItem("cityId", String(cityId));
                    localStorage.setItem("cityName", String(cityName));

                    navigate(
                      `/package-tour/${
                        subPathFromSubCategoryName.find((group) => group.name === item?.subcategory_name)?.subPath
                      }?edit=${true}&type=booking`,
                      { state: item }
                    );
                  } else if (result.dismiss === Swal.DismissReason.cancel) {
                    return;
                  }
                });
              } else {
                let cityName = subPathWithAbbreviation.find((it: any) => it.abb == item?.subcategory_name.split(" ")[0])?.name;
                let cityId = cityData.cityData.find((it: any) => it.name == cityName)?.id;
                setCityId(cityId);
                localStorage.setItem("cityId", String(cityId));
                localStorage.setItem("cityName", String(cityName));

                navigate(
                  `/package-tour/${
                    subPathFromSubCategoryName.find((group) => group.name === item?.subcategory_name)?.subPath
                  }?edit=${true}&type=booking`,
                  { state: item }
                );
              }
            }
          }}
          className="py-2 underline text-sky-700"
        >
          Edit
        </button>
      )}
    </div>
  );
};

const extractParams = (url: string) => {
  const urlParams = new URLSearchParams(new URL(url).search);
  const email = urlParams.get("email");
  const order_number = urlParams.get("order_number");

  return { email, order_number };
};

export const BookingsView = () => {
  const [showModal, setShowModal] = useState(-100);
  const [modalForm, setModalForm] = useState({});
  const [draftForm, setDraftForm] = useState({} as any);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isDownLoadTicket, setIsDownLoadTicket] = useState(false);
  const [reservationsData, setReservationsData] = reservationsState.useState();
  const [reservationsParsedData, setReservationsParsedData] = reservationsParsedState.useState();

  const navigate = useNavigate();

  const { search } = useLocation(); // ?email=jlfl94@gmail.com&order_number=4388608
  let searchQuery: any;
  if (search) {
    searchQuery = extractParams("http://localhost:3000/my-bookings" + search);
  }

  const handleShow = (show: number) => {
    setShowModal(show);
    if (show < 0) {
      setDraftForm({});
      setIsSubmitted(false);
    }
  };

  const handleChange = (event: any, key: number, index: number) => {
    const { name, value } = event.target;
    // console.log("event changed", { key, name, value, index });
    setDraftForm((prevForm: any) => {
      let val = prevForm[String(key)];
      if (val === undefined || !Array.isArray(val)) {
        val = new Array(3).fill(null);
      }
      val[index] = value;
      return { ...prevForm, [String(key)]: val };
    });
  };

  const handleSubmit = async (event: any, key: number, orderNumber: string, ticketName: string, allowMultipe: boolean) => {
    event.preventDefault();
    // console.log("submitted", draftForm);

    const submittedForm = draftForm[String(key)];

    const isValid = submittedForm?.reduce((acc: any, item: any) => {
      return acc && typeof item === "string";
    }, true);

    if (isValid) {
      if (allowMultipe) {
        // Find items for same order number and setting schedule options at the same time
        let itemsInSameOrder = parsedData.find((it: any) => it.order_number === orderNumber);

        let subItemIdsToSchedule: any[] = [];
        itemsInSameOrder?.items?.forEach((it: any) => {
          it.tickets.forEach((singleTicket: any) => {
            if (singleTicket.options_schedules.length === 0 && !singleTicket.rq_schedule_datetime && singleTicket.ticket_title_kr === ticketName) {
              subItemIdsToSchedule.push(singleTicket.subitem_id);
            }
          });
        });

        try {
          await Promise.all(
            subItemIdsToSchedule.map((it) => {
              return updateScheduleOptions(it, submittedForm);
            })
          );

          setIsSubmitted(true);

          subItemIdsToSchedule.forEach((it: any) => {
            setModalForm((prevForm: any) => {
              return { ...prevForm, [String(it)]: submittedForm };
            });
          });
        } catch (e) {
          setIsSubmitted(true);
          subItemIdsToSchedule.forEach((it: any) => {
            setModalForm((prevForm: any) => {
              return { ...prevForm, [String(it)]: submittedForm };
            });
          });
        }
      } else {
        try {
          const res = await updateScheduleOptions(key, submittedForm);
          setIsSubmitted(true);

          setModalForm((prevForm: any) => {
            return { ...prevForm, [String(key)]: submittedForm };
          });
        } catch (e) {
          setIsSubmitted(true);

          setModalForm((prevForm: any) => {
            return { ...prevForm, [String(key)]: submittedForm };
          });
        }
      }
    }
  };

  const { isLoading, isError, data } = useGetReservations(searchQuery, isSubmitted, isDownLoadTicket);
  // const {  isLoading, data, isError } = useGetReservations(searchQuery, isSubmitted);

  useEffect(() => {
    if (data) {
      if (data.reservations) {
        // Create a new array or object with the updated data
        const updatedReservations = { reservations: [...data.reservations] };
        const updatedParsedReservations = {
          reservations: [
            ...data?.reservations
              ?.slice()
              .sort((a: any, b: any) => {
                const dateComparison = new Date(b?.order_date).getTime() - new Date(a?.order_date).getTime();
                if (dateComparison !== 0) {
                  return dateComparison;
                }
                return b?.reservation_items[0].id - a?.reservation_items[0].id;
              })
              ?.map((reservation: any) => {
                return {
                  order_number: reservation?.order_number,
                  order_date: reservation?.order_date,
                  reservation_id: reservation.id,
                  items: reservation?.reservation_items
                    ?.slice()
                    .sort((a: any, b: any) => b?.id - a?.id)
                    ?.map((item: any) => {
                      return {
                        item_id: item?.id,
                        reservation_id: reservation?.id,
                        price_list_id: item?.price_list_id,
                        hasSubcategory: !!item?.subcategory_id,
                        product_name: parsedPriceOptions?.get(String(item?.price_list_id)),
                        subcategory_name:
                          // parsedPriceOptions?.get(item?.price_list_id) ||
                          parsedSubcategories?.get(String(item?.subcategory_id))?.name,
                        subcategory_id: item?.subcategory_id,
                        adult_child_type: item?.adult_child_type,
                        child_age: item?.child_age,
                        quantity: item?.quantity,
                        price: item?.price,
                        addition: item?.addition,
                        total: item?.total,
                        refund_status: item?.refund_status,
                        tickets: item?.reservation_sub_items
                          ?.slice()
                          .sort((a: any, b: any) => b?.id - a?.id)
                          ?.map((subitem: any) => {
                            return {
                              subitem_id: subitem?.id,
                              rq_schedule_datetime: subitem?.rq_schedule_datetime,
                              ticket_sent_status: subitem?.ticket_sent_status,
                              refund_status: subitem?.refund_status,
                              ticket_id: subitem?.ticket_id,
                              price: subitem?.price,
                              addition: subitem?.addition,
                              quantity: subitem?.quantity,
                              total: subitem?.total,
                              ticket_type: parsedTickets?.get(String(subitem?.ticket_id))?.ticket_type,
                              ticket_title_en: parsedTickets?.get(String(subitem?.ticket_id))?.title_en,
                              ticket_title_kr: parsedTickets?.get(String(subitem?.ticket_id))?.title_kr,
                              additional_price_type: parsedTickets?.get(String(subitem?.ticket_id))?.additional_price_type,
                              additional_price_image: parsedTickets?.get(String(subitem?.ticket_id))?.additional_price_image,
                              additional_price_amount: parsedTickets?.get(String(subitem?.ticket_id))?.additional_price_amount,
                            };
                          }),
                      };
                    }),
                };
              }),
          ],
        };

        // Assign the updated value to the reservations state
        setReservationsData(updatedReservations);
        setReservationsParsedData(updatedParsedReservations);
      }
    }
  }, [data]);

  const parsedPriceOptions = useMemo(() => {
    const priceOptionsMap = new Map();
    if (!isLoading && !isError && data) {
      data?.priceOptions?.forEach((option: any) => {
        priceOptionsMap.set(String(option.id), option.product_type);
      });
    }
    return priceOptionsMap;
  }, [data, isLoading, isError]);

  const parsedScheduleOptions = useMemo(() => {
    let options = {} as any;
    if (!isLoading && !isError && data) {
      data?.scheduleOptions?.forEach((option: any) => {
        const optionsData = option;
        if (optionsData && optionsData.length > 0) {
          const optionsInOrder: string[] = optionsData
            .sort((a: any, b: any) => a?.order - b?.order)
            .map((option: any) => convertToISO8601(option.datetime));
          options[String(optionsData[0]?.reservation_sub_item_id)] = optionsInOrder;
        }
      });
    }
    return options;
  }, [data, isLoading, isError]);

  const parsedTickets = useMemo(() => {
    const tickets = new Map();
    if (!isLoading && !isError && data) {
      data?.tickets?.forEach((ticket: any) => {
        const ticket_id = ticket?.id;
        if (ticket_id) {
          tickets.set(String(ticket_id), ticket);
        }
      });
    }
    return tickets;
  }, [data, isLoading, isError]);

  const parsedSubcategories = useMemo(() => {
    const subcategories = new Map();
    if (!isLoading && !isError && data) {
      data?.categories?.forEach((category: any) => {
        const id = category?.id;
        if (id && !subcategories.has(id)) {
          subcategories.set(String(id), category);
        }
      });
    }
    return subcategories;
  }, [data, isLoading, isError]);

  const parsedData: any = useMemo(() => {
    return data?.reservations
      ?.slice()
      .sort((a: any, b: any) => {
        const dateComparison = new Date(b?.order_date).getTime() - new Date(a?.order_date).getTime();
        if (dateComparison !== 0) {
          return dateComparison;
        }
        return b?.reservation_items[0].id - a?.reservation_items[0].id;
      })
      ?.map((reservation: any) => {
        return {
          order_number: reservation?.order_number,
          order_date: reservation?.order_date,
          items: reservation?.reservation_items
            ?.slice()
            .sort((a: any, b: any) => b?.id - a?.id)
            ?.map((item: any) => {
              return {
                reservation_id: reservation?.id,
                price_list_id: item?.price_list_id,
                hasSubcategory: !!item?.subcategory_id,
                product_name: parsedPriceOptions?.get(String(item?.price_list_id)),
                subcategory_name:
                  // parsedPriceOptions?.get(item?.price_list_id) ||
                  parsedSubcategories?.get(String(item?.subcategory_id))?.name,
                subcategory_id: item?.subcategory_id,
                item_id: item?.id,
                adult_child_type: item?.adult_child_type,
                child_age: item?.child_age,
                quantity: item?.quantity,
                price: item?.price,
                addition: item?.addition,
                total: item?.total,
                refund_status: item?.refund_status,
                tickets: item?.reservation_sub_items
                  ?.slice()
                  .sort((a: any, b: any) => b?.id - a?.id)
                  ?.map((subitem: any) => {
                    return {
                      subitem_id: subitem?.id,
                      pdf_path: subitem?.pdf_path,
                      rq_schedule_datetime: subitem?.rq_schedule_datetime,
                      ticket_sent_status: subitem?.ticket_sent_status,
                      options_schedules: subitem?.options_schedules,
                      refund_status: subitem?.refund_status,
                      ticket_id: subitem?.ticket_id,
                      price: subitem?.price,
                      addition: subitem?.addition,
                      quantity: subitem?.quantity,
                      total: subitem?.total,
                      ticket_type: parsedTickets?.get(String(subitem?.ticket_id))?.ticket_type,
                      ticket_title_en: parsedTickets?.get(String(subitem?.ticket_id))?.title_en,
                      ticket_title_kr: parsedTickets?.get(String(subitem?.ticket_id))?.title_kr,
                      additional_price_type: parsedTickets?.get(String(subitem?.ticket_id))?.additional_price_type,
                      additional_price_image: parsedTickets?.get(String(subitem?.ticket_id))?.additional_price_image,
                      additional_price_amount: parsedTickets?.get(String(subitem?.ticket_id))?.additional_price_amount,
                    };
                  }),
              };
            }),
        };
      });
  }, [data, parsedSubcategories, parsedTickets, parsedPriceOptions]);

  const checkTokenExpiration = () => {
    const token = localStorage.getItem("authToken");

    if (token) {
      const decodedToken: any = jwtDecode(token);

      const currentTime = Date.now() / 1000; // Convert current time to seconds

      if (decodedToken.exp < currentTime) {
        // Token has expired, force logout here
        // For example, clear the token from local storage and redirect the user to the login page
        localStorage.removeItem("authToken");
        localStorage.removeItem("loginData");
        localStorage.removeItem("order_number");
        localStorage.removeItem("useremail");

        navigate("/");
      }
    }
  };

  useEffect(() => {
    const intervalId = setInterval(checkTokenExpiration, 1000); // Check token expiration every second
    return () => clearInterval(intervalId); // Clear the interval when the component unmounts
  }, []);

  useEffect(() => {
    setModalForm((prevState) => ({ ...prevState, ...parsedScheduleOptions }));
  }, [parsedScheduleOptions]);

  // TODO: Clean up console.log
  // console.log("my bookings data", {
  //   data,
  //   modalForm,
  //   parsedTickets,
  //   parsedSubcategories,
  //   parsedData,
  //   parsedPriceOptions,
  // });

  return (
    <>
      {!isLoading && parsedData && (
        <div className="flex flex-col w-full p-8 bg-white">
          {!localStorage.getItem("orderLoginNumber") && <span className="mb-5 text-xl font-bold font-poppins">My Bookings</span>}

          {localStorage.getItem("orderLoginNumber") && (
            <div className="flex justify-between mb-10 ">
              <span className="mb-5 text-xl font-bold font-poppins text-[#5D5D5F]">My Bookings</span>
              <div>
                <div className="flex justify-end text-[#5D5D5F]">Order Date: {parsedData[0]?.order_date}</div>
                <div className="flex justify-end text-[#5D5D5F]">Order Number: {parsedData[0]?.order_number}</div>
              </div>
            </div>
          )}
          {parsedData?.map((reservation: any, i: number) => {
            return (
              <div key={i} className="w-full mb-10">
                {i > 0 && <hr className="my-12 h-0.5 border-t-0 bg-neutral-100 opacity-100 dark:opacity-50" />}
                {!localStorage.getItem("orderLoginNumber") && (
                  <div className="flex justify-end text-[#5D5D5F] text-xs sm:text-sm">Order Number: {reservation.order_number}</div>
                )}
                {!localStorage.getItem("orderLoginNumber") && (
                  <div className="flex justify-end pb-5 text-[#5D5D5F] text-xs sm:text-sm">Order Date: {reservation.order_date}</div>
                )}

                <div className="grid grid-cols-4 sm:grid-cols-7 text-xs sm:text-sm text-[#5D5D5F] text-center">
                  <div className="px-2 py-1">상품</div>
                  <div className="px-2 py-1">Adult/Child</div>
                  <div className="px-2 py-1  hidden sm:block">Scheduled Date</div>
                  <div className="px-2 py-1">Quantity</div>
                  <div className="px-2 py-1  hidden sm:block">Ticket Sent Status</div>
                  <div className="px-2 py-1 hidden sm:block">Item ID</div>
                  <div className="px-2 py-1"></div>
                </div>
                <hr className="my-3 h-0.5 border-t-0 bg-black opacity-40 dark:opacity-40" />
                {reservation?.items?.map((item: any, i: number) => {
                  if (item?.hasSubcategory) {
                    return (
                      <>
                        <SubcategoryView item={item} i={i} />
                        <TicketViewMemo
                          orderNumber={reservation?.order_number}
                          item={item}
                          reservation={reservation}
                          showModal={showModal}
                          handleShow={handleShow}
                          modalForm={modalForm}
                          handleChange={handleChange}
                          handleSubmit={handleSubmit}
                          setIsDownLoadTicket={setIsDownLoadTicket}
                          isSubmitted={isSubmitted}
                          hasSubcategory={true}
                        />
                      </>
                    );
                  } else {
                    return (
                      <TicketViewMemo
                        orderNumber={reservation?.order_number}
                        item={item}
                        reservation={reservation}
                        showModal={showModal}
                        handleShow={handleShow}
                        modalForm={modalForm}
                        handleChange={handleChange}
                        handleSubmit={handleSubmit}
                        setIsDownLoadTicket={setIsDownLoadTicket}
                        isSubmitted={isSubmitted}
                        hasSubcategory={false}
                      />
                    );
                  }
                })}
              </div>
            );
          })}
        </div>
      )}
    </>
  );
};
