import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import jwtDecode from "jwt-decode";

import { PackageBuyDetail } from "./components/PackageBuyDetail";
import { ProductCard } from "../../shared/components/ProductCard";
import { MainButton } from "../../shared/components/Buttons";
import { useGetTicket, useGetTickets } from "../../shared/hooks";
import { cityIdState, menuState, pageNumState } from "../../App";
import { API } from "../../shared";
export const BigApplePassView = () => {
  const [displayFilter, setDisplayFilter] = useState(false);
  const [menu] = menuState.useState();
  const [cityId] = cityIdState.useState();
  const [totalNum, setTotalNum] = pageNumState.useState();
  const [page, setPage] = useState(totalNum);
  const [isShowing, setIsShowing] = useState(true);
  const navigate = useNavigate();
  const [ticket, setTicket] = useState<any>([]);
  const [isFirst, setIsFirst] = useState(true);
  const checkTokenExpiration = () => {
    const token = localStorage.getItem("authToken");

    if (token) {
      const decodedToken: any = jwtDecode(token);

      const currentTime = Date.now() / 1000; // Convert current time to seconds

      if (decodedToken.exp < currentTime) {
        // Token has expired, force logout here
        // For example, clear the token from local storage and redirect the user to the login page
        localStorage.removeItem("authToken");
        localStorage.removeItem("loginData");
        localStorage.removeItem("order_number");
        localStorage.removeItem("useremail");

        navigate("/");
      }
    }
  };

  const handleClick = () => {
    if (tickets) {
      setPage(tickets.length); // Set the page number to the total number of tickets
      setIsShowing(false); // Hide the "Load More" button
    }
  };

  // **control number of tickets to display**
  // const handleClick = () => {
  //   if (!!tickets) {
  //     if (tickets.length <= page + 5) {
  //       setPage(tickets.length);
  //       setTotalNum(tickets.length);
  //       setIsShowing(false);
  //     } else {
  //       setPage(page + 5);
  //       setTotalNum(page + 5);
  //     }
  //   }
  // }
  useEffect(() => {
    // console.log("tickets are ", tickets);
    const intervalId = setInterval(checkTokenExpiration, 1000); // Check token expiration every second
    return () => clearInterval(intervalId); // Clear the interval when the component unmounts
  }, []);

  useEffect(() => {
    setPage(totalNum || 5);
  }, [cityId]);

  const { tickets: allTickets } = useGetTickets({
    category: Number(menu[0]?.dropdownElements?.[0].category_id),
    subCategoryId: Number(menu[0]?.dropdownElements?.[0].id),
    menu,
  });

  // Check if allTickets is defined and not empty before slicing
  const tickets = allTickets?.slice(0, 10000) || [];

  return (
    <div className="flex flex-col w-full gap-x-8 md:flex-row">
      <div className="flex flex-col w-full md:w-2/3 gap-y-8">
        {displayFilter ? (
          <PackageBuyDetail tour={true} handlePage={setPage} allTickets={tickets} tickets={tickets?.slice(0, page) || []} />
        ) : (
          tickets?.slice(0, page)?.map((item) => <ProductCard key={item.name} {...item} />)
        )}
        {isShowing ? (
          <div className="flex justify-center text-base text-blue hover:cursor-pointer hover:underline font-poppins" onClick={handleClick}>
            SHOW ALL
          </div>
        ) : (
          <></>
        )}
        {/* <MainButton
          text={"티켓구입"}
          containerClassName="w-full block md:hidden"
          onClick={() => setDisplayFilter((prev) => !prev)}
        /> */}
      </div>
      <div className="w-full mt-8 md:w-1/3 md:block font-poppins md:mt-0">
      <PackageBuyDetail
          tour={true}
          handlePage={setPage}
          allTickets={tickets}
          tickets={tickets || []} // Use the updated tickets array
          categoryId={Number(menu[0]?.dropdownElements?.[0].category_id)}
          subCategoryId={Number(menu[0]?.dropdownElements?.[0].id)}
          subCategoryTitle={"SF Big Apple Pass"}
          subPath={"ba-pass"}
        />
      </div>
    </div>
  );
};
