import { staticFiles } from "..";
import { MainButton } from "./Buttons";
import { SpaceY } from "./Utils";

export const InfoCover = () => (
  <>
    <div className="flex flex-col lg:hidden justify-between px-[5vw] max-w-[1300px]">
      <SpaceY />
      <div className="flex flex-col">
        <div className="flex flex-col items-center w-full">
          <span className="font-poppins font-medium text-[#DF6951]">Best Destinations around the world</span>
          <span className="font-volkhov font-bold text-dark text-2xl lg:text-3xl leading-tight">Travel, enjoy your vacation</span>
        </div>
      </div>
      <SpaceY /> <SpaceY />
      <div className="flex justify-center">
        <img src={staticFiles.images.cover_bg_complete} />
      </div>
      <SpaceY /> <SpaceY />
      <div className="flex flex-col">
        <div className="font-poppins text-dargGray">
          Built Wicket longer admire do barton vanity itself do in it. Preferred to sportsmen it engrossed listening. Park gate sell they west hard for the.
        </div>
        <SpaceY />
        <div className="flex">
          <MainButton containerClassName="w-[200px] rounded" text="Learn more" onClick={() => {}} />
        </div>
      </div>
    </div>

    <div className="max-h-[420px] hidden lg:flex justify-between px-[3.8rem] max-w-[1300px] my-4 mt-[110px]">
      <div className="flex flex-col justify-between w-7/12">
        <div className="flex flex-col w-full">
          <span className="font-poppins font-medium text-[#DF6951]">Best Destinations around the world</span>
          <span className="font-volkhov font-bold text-dark text-2xl lg:text-3xl leading-tight">Travel, enjoy your vacation</span>
        </div>

        <div className="font-poppins text-dargGray mr-20">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat
        </div>
        <div className="md:flex flex-col hidden">
          <SpaceY />
          <div className="flex">
            <MainButton containerClassName="w-[200px] rounded" text="Learn more" onClick={() => {}} />
          </div>
        </div>
      </div>
      <div className="flex w-10/12 items-end justify-end">
        <img className="max-h-[420px] min-h-[200px]" src={staticFiles.images.cover_bg_complete} />
      </div>
    </div>
  </>
);
