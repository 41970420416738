import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate, useLocation } from "react-router-dom";
import jwtDecode from "jwt-decode";
import axios from "axios";
import Swal from "sweetalert2";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";

import parsePhoneNumberFromString from "libphonenumber-js";
import { getName } from "country-list";

import { MainInput, PhoneInput } from "../../shared/components/Inputs";
import { SpaceY } from "../../shared/components/Utils";
import { GhostButton, MainButton } from "../../shared/components/Buttons";
import { CartItem, cartState, reservationsState, orderLookupState } from "../../App";
import { API, non_member_credentials } from "../../shared";
import { getUserProfile } from "../../shared/hooks";
import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from "@material-ui/core";
import { toast } from "react-toastify";

type cartData = {
  adultInfo: CartItem[];
  childInfo: CartItem[];
};

const getTodayDate = () => {
  // Get today's date
  const today = new Date();

  // Extract year, month, and day from the date object
  const year = today.getFullYear();
  const month = String(today.getMonth() + 1).padStart(2, "0"); // Months are zero-indexed, so we add 1
  const day = String(today.getDate()).padStart(2, "0");

  // Combine the values to form the desired format
  return `${year}-${month}-${day}`;
};

const getAuthToken = () => localStorage.getItem("authToken");
const loginData = () => localStorage.getItem("loginData");

const getSubTotal = (ticket: CartItem, map: Map<any, any>) => {
  let totalPrice = 0;
  let key = ticket?.cartId;
  let subCategoryId = ticket?.subCategoryId;
  if (key && subCategoryId) {
    let existingTickets = map.get(key) || [];
    if (existingTickets.length === 0) {
      totalPrice += (Number(ticket?.subCategoryPrice) + Number(ticket?.addition)) * Number(ticket?.quantity);
    } else {
      totalPrice += Number(ticket?.addition) * Number(ticket?.quantity);
    }
    map.set(key, existingTickets.concat(ticket));
  } else {
    map.set(key, [ticket]);
    totalPrice += (Number(ticket?.price) + Number(ticket?.addition)) * Number(ticket?.quantity);
  }
  return totalPrice;
};

const checkIfBookingEdit = ({ childInfo, adultInfo }: { childInfo: CartItem[]; adultInfo: CartItem[] }) => {
  const combinedInfo = [...childInfo, ...adultInfo];
  let result = false;

  for (let item of combinedInfo) {
    if (item.reservation_id != null) result = true;
    break;
  }

  return result;
};

const parseCartData = (data: cartData) => {
  const combinedCartData = [...data.adultInfo, ...data.childInfo];
  const groupByCartId = new Map();
  let totalPrice = 0;

  combinedCartData?.forEach((ticket: CartItem) => {
    totalPrice += getSubTotal(ticket, groupByCartId);
  });

  const formatReservationInput: any[] = [];
  Array.from(groupByCartId.values()).forEach((cart) => {
    const item: any = {};
    if (cart[0].subCategoryId) {
      item.category_id = cart[0].categoryId;
      item.child_age = null;
      item.child_age = null;
      item.subcategory_id = Number(cart[0].subCategoryId);
      item.price_list_id = cart[0].priceOptionId;
      item.adult_child_type = cart[0].adult_child_type;
      item.quantity = cart[0].quantity;
      item.price = cart[0].subCategoryPrice;
      item.sub_items = cart.map((sub_item: any) => {
        if (sub_item?.tour_date) {
          const datetimeString = sub_item?.tour_date;

          // Extracting components from the datetime string
          const year = datetimeString.substring(0, 4);
          const month = datetimeString.substring(5, 7) - 1; // Subtract 1 because months start from 0 (January is 0)
          const day = datetimeString.substring(8, 10);
          let hours = parseInt(datetimeString.substring(11, 13));
          const minutes = parseInt(datetimeString.substring(14, 16));
          const ampm = datetimeString.substring(16);

          // Adjusting hours for AM/PM format
          if (ampm === "PM" && hours !== 12) {
            hours += 12;
          } else if (ampm === "AM" && hours === 12) {
            hours = 0;
          }

          // Creating the new Date object
          let newDate = new Date(year, month, day, hours, minutes);
          console.log(newDate);
          return {
            ticket_id: sub_item?.ticket_id,
            rq_schedule_datetime: `${newDate.getFullYear()}-${(newDate.getMonth() + 1).toString().padStart(2, "0")}-${newDate.getDate().toString().padStart(2, "0")} ${newDate
              .getHours()
              .toString()
              .padStart(2, "0")}:${newDate.getMinutes().toString().padStart(2, "0")}:${newDate.getSeconds().toString().padStart(2, "0")}`,
          };
        } else {
          return {
            ticket_id: sub_item?.ticket_id,
            rq_schedule_datetime: null,
          };
        }
      });
      let addition = 0;
      cart?.forEach((ticket: any) => {
        addition += Number(ticket?.addition) * Number(ticket?.quantity);
      });
      item.subTotal = cart[0].subCategoryPrice * cart[0].quantity + addition;
    } else {
      item.category_id = cart[0].categoryId || "1";
      item.child_age = null;
      item.subcategory_id = null;
      item.price_list_id = cart[0].priceOptionId || null;
      item.adult_child_type = cart[0].adult_child_type;
      item.quantity = cart[0].quantity;
      item.price = Number(cart[0].price);
      item.sub_items = cart.map((sub_item: any) => {
        if (sub_item?.tour_date) {
          const datetimeString = sub_item?.tour_date;

          // Extracting components from the datetime string
          const year = datetimeString.substring(0, 4);
          const month = datetimeString.substring(5, 7) - 1; // Subtract 1 because months start from 0 (January is 0)
          const day = datetimeString.substring(8, 10);
          let hours = parseInt(datetimeString.substring(11, 13));
          const minutes = parseInt(datetimeString.substring(14, 16));
          const ampm = datetimeString.substring(16);

          // Adjusting hours for AM/PM format
          if (ampm === "PM" && hours !== 12) {
            hours += 12;
          } else if (ampm === "AM" && hours === 12) {
            hours = 0;
          }

          // Creating the new Date object
          let newDate = new Date(year, month, day, hours, minutes);

          console.log(newDate);
          return {
            ticket_id: sub_item?.ticket_id,
            rq_schedule_datetime: `${newDate.getFullYear()}-${(newDate.getMonth() + 1).toString().padStart(2, "0")}-${newDate.getDate().toString().padStart(2, "0")} ${newDate
              .getHours()
              .toString()
              .padStart(2, "0")}:${newDate.getMinutes().toString().padStart(2, "0")}:${newDate.getSeconds().toString().padStart(2, "0")}`,
          };
        } else {
          return {
            ticket_id: sub_item?.ticket_id,
            rq_schedule_datetime: null,
          };
        }
      });
      let addition = 0;
      cart?.forEach((ticket: any) => {
        addition += Number(ticket?.addition) * Number(ticket?.quantity);
      });
      item.subTotal = Number(cart[0].price) * cart[0].quantity + addition;
    }
    formatReservationInput.push(item);
  });

  return {
    data: formatReservationInput,
    totalPrice,
  };
};

const extractParams = (url: string) => {
  const urlParams = new URLSearchParams(new URL(url).search);
  const guestCheckout = urlParams.get("guestCheckout") === "true";
  return { guestCheckout };
};

function Checkout({ totalPrice }: any) {
  const navigate = useNavigate();
  const { search } = useLocation(); // ?email=jlfl94@gmail.com&order_number=4388608
  let { guestCheckout } = extractParams("http://localhost:3000/my-bookings" + search);

  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [hasUserProfile, setHasUserProfile] = useState(false);
  const [cart, setCart] = cartState.useState();
  const [reservationsData] = reservationsState.useState();
  const [orderLookup, setOrderLookup] = orderLookupState.useState();
  const [discount, setDiscount] = useState("");
  const [credit, setCredit] = useState("");
  const [paymentType, setPaymentType] = useState("credit_card");
  //square payment
  const [paymentStatus, setPaymentStatus] = useState("");
  const [card, setCard] = useState<any>(null);

  const authToken = getAuthToken();
  const isLoggedIn = authToken && loginData() === "email";

  const schema = yup.object().shape({
    koreanFullName: yup.string().nullable(),
    lastName: yup.string().nullable(),
    firstName: yup.string().nullable(),
    email: yup.string().email().nullable(),
    confirmEmail: yup.string().email().nullable(),
    phone: yup.string().nullable(),
    departureDate: yup.string().nullable(),
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
    setError,
    reset,
    getValues,
  } = useForm({
    defaultValues: {
      koreanFullName: "",
      lastName: "",
      firstName: "",
      email: "",
      confirmEmail: "",
      phone: "",
      departureDate: "",
    },
    resolver: yupResolver(schema),
  });

  const stripe = useStripe();
  const elements = useElements();

  useEffect(() => {
    if (localStorage.getItem("orderLoginNumber")) {
      // if non-member orderLookup
      reset({
        ...getValues(),
        koreanFullName: localStorage.getItem("customer_name_kr") || "",
        lastName: localStorage.getItem("customer_name_en") ? (localStorage.getItem("customer_name_en") || "").split(" ")[1] : "",
        firstName: localStorage.getItem("customer_name_en") ? (localStorage.getItem("customer_name_en") || "").split(" ")[0] : "",
        email: localStorage.getItem("orderLoginEmail") || "",
        confirmEmail: localStorage.getItem("orderLoginEmail") || "",
        phone: localStorage.getItem("phone") || "",
        departureDate: "",
      });
      setHasUserProfile(true);
    } else {
      //if not non-member orderLookup
      if (!guestCheckout) {
        // logged users
        const getUserData = async () => {
          const data = await getUserProfile(guestCheckout);
          if (data) {
            const role = localStorage.getItem("role");
            if (role === "1" || role === "2") {
              reset({
                ...getValues(),
                koreanFullName: "",
                lastName: "",
                firstName: "",
                email: "",
                confirmEmail: "",
                phone: "",
              });
              setHasUserProfile(false);
            } else {
              reset({
                ...getValues(),
                koreanFullName: data.user?.name,
                lastName: data.user?.lastname,
                firstName: data.user?.firstname,
                email: data.user?.email,
                confirmEmail: data.user?.email,
                phone: data.user?.phone,
              });
              setHasUserProfile(true);
            }
          }

          // // if the admin user is entered this page due to editing...
          // if (localStorage.getItem("customerInfo")) {
          //   const customerInfo = localStorage.getItem("customerInfo");
          //   if (customerInfo) {
          //     reset({
          //       ...getValues(),
          //       ...JSON.parse(customerInfo)
          //     });
          //     setHasUserProfile(true);
          //   }
          // }
        };
        getUserData();
      } else {
        // guest checkout
        reset({
          ...getValues(),
          koreanFullName: "",
          lastName: "",
          firstName: "",
          email: "",
          confirmEmail: "",
          phone: "",
          departureDate: "",
        });
      }
    }
  }, [guestCheckout, orderLookup, localStorage.getItem("orderLoginNumber")]);

  useEffect(() => {
    return () => {
      console.log("unmounting cart view");
      reset();
      Swal.close();
    };
  }, []);

  useEffect(() => {
    const role = localStorage.getItem("role");
    if ((paymentType === "credit_card" && authToken) || guestCheckout || localStorage.getItem("orderLoginNumber")) {
      const applicationId = "sandbox-sq0idb-GbPCt5CrqALfeMNNdylebg";
      const locationId = "LHPS0B8GS1HFD";
      if (window.Square) {
        const payments = window.Square.payments(applicationId, locationId);
        payments.card().then((card: any) => {
          setCard(card);
          document.querySelector<HTMLDivElement>("#card-container")!.innerHTML = "";
          card.attach("#card-container");
        });
      }
    }
  }, [paymentType]);

  // console.log("user profile", {
  //   isLoggedIn,
  //   guestCheckout,
  //   getValues: getValues(),
  //   userProfile,
  // });

  const handleCardNumberChange = (value: any, onChange: any) => {
    const numericValue = value.replace(/[^0-9]/g, "");
    onChange(numericValue);
  };

  const handleChange = (value: string) => {
    const num = value.includes("$") ? value.substring(1) : value;
    const max = parseFloat(totalPrice) - parseDiscount(credit);
    setDiscount(isDiscountBelowZero(num) ? num : max.toString());
  };

  const parseDiscount = (value: string) => {
    if (value === "") return 0;
    else return parseFloat(value);
  };

  const handlePaymentChange = (value: string) => {
    setPaymentType(value);
  };

  const handleCreditChange = (value: string) => {
    const num = value.includes("$") ? value.substring(1) : value;
    const max = parseFloat(totalPrice) - parseDiscount(discount);
    setCredit(isCreditBelowZero(num) ? num : max.toString());
  };

  const isCreditBelowZero = (num: string) => {
    return parseFloat(totalPrice) - parseDiscount(discount) - parseDiscount(num) >= 0;
  };

  const isDiscountBelowZero = (num: string) => {
    return parseFloat(totalPrice) - parseDiscount(credit) - parseDiscount(num) >= 0;
  };

  const onSubmit = async (formData: any) => {
    let hasEmptyValue = Object.keys(getValues()).some((key) => key !== "departureDate" && (formData[key] === "" || formData[key] === undefined));

    if (hasEmptyValue) {
      // One or more form values are empty
      Swal.fire({
        icon: "warning",
        title: "Contact Details Missing",
        text: "Please input all your contact details",
      });
    } else {
      // All form values are non-empty
      if (formData.email !== formData.confirmEmail) {
        return setError("confirmEmail", {
          message: "Email does not match",
        });
      } else {
        let stripeToken;
        if (paymentType === "credit_card") {
          // if (elements && stripe) {
          //   const card = elements.getElement(CardElement);
          //   if (card) {// Handle null or undefined value
          //     // const { error, paymentMethod } = await stripe.createPaymentMethod({
          //     //   type: "card",
          //     //   card: card,
          //     // });
          //     const { token, error } = await stripe.createToken(card);

          //     if (!error) {
          //       stripeToken = token.id;
          //       setHasSubmitted(true);

          //       try {
          //         let isEdit = checkIfBookingEdit(cart);
          //         // Parse the cart data
          //         const { data: cartData } = parseCartData(cart);

          //         if (isEdit) {
          //           let apiUrls: any = [];
          //           const combinedCartData = [...cart.adultInfo, ...cart.childInfo];
          //           // Get distinct reservation Ids
          //           const reservationIds = combinedCartData.map((obj: any) => obj.reservation_id);
          //           let distinctReservationIds: any = [];

          //           for (let i = 0; i < reservationIds.length; i++) {
          //             if (distinctReservationIds.indexOf(reservationIds[i]) === -1) {
          //               distinctReservationIds.push(reservationIds[i]);
          //             }
          //           }

          //           for (let reservationId of distinctReservationIds) {
          //             let items: any = [];
          //             let subItems = combinedCartData.filter((it: any) => it.reservation_id === reservationId && it.subtotal !== -100)
          //             let reservations = reservationsData.reservations;
          //             let currentReservation: any = reservations[reservations.findIndex((it: any) => it.id === reservationId)];

          //             let currentItemBeforeUpdate: any = currentReservation.reservation_items.filter((it: any) => it.id === subItems[0].item_id);
          //             let otherItems = currentReservation.reservation_items.filter((it: any) => it.id !== subItems[0].item_id);
          //             let otherItemsTosend = otherItems.map((data: any) => (
          //               {
          //                 id: data.id,
          //                 adult_child_type: data.adult_child_type,
          //                 child_age: data.child_age,
          //                 price: parseFloat(data.price) || 0,
          //                 quantity: parseInt(data.quantity),
          //                 ticket_sent_status: data.ticket_sent_status,
          //                 ticket_sent_date: data.ticket_sent_date,
          //                 refund_status: data.refund_status,
          //                 refund_sent_date: data.refund_sent_date,
          //                 reservation_id: parseFloat(data.reservation_id),
          //                 category_id: parseFloat(data.category_id),
          //                 subcategory_id: parseFloat(data.subcategory_id),
          //                 price_list_id: parseFloat(data.price_list_id),
          //                 sub_items: data.reservation_sub_items?.map((subitem: any) => ({
          //                   id: subitem.id,
          //                   rq_schedule_datetime: subitem.rq_schedule_datetime,
          //                   ticket_id: subitem.ticket_id,
          //                   refund_status: subitem.refund_status,
          //                 })) || [],
          //               }
          //             ));

          //             items.push(...otherItemsTosend);

          //             let currentItem: any = {
          //               id: subItems[0].item_id,
          //               adult_child_type: subItems[0].adult_child_type,
          //               child_age: null,

          //               price: subItems[0].subCategoryPrice,
          //               quantity: subItems[0].quantity,

          //               ticket_sent_status: null,
          //               ticket_sent_date: null,
          //               refund_status: null,
          //               refund_sent_date: null,

          //               reservation_id: subItems[0].reservation_id,

          //               category_id: subItems[0].categoryId ? parseFloat(subItems[0].categoryId) : null,
          //               subcategory_id: subItems[0].subCategoryId,
          //               price_list_id: subItems[0].priceOptionId ? parseFloat(subItems[0].priceOptionId) : null,

          //               sub_items: [...subItems.map((it: any, index: number) => {
          //                 if (it.tour_date) {
          //                   const datetimeString = it.tour_date;

          //                   // Extracting components from the datetime string
          //                   const year = datetimeString.substring(0, 4);
          //                   const month = datetimeString.substring(5, 7) - 1; // Subtract 1 because months start from 0 (January is 0)
          //                   const day = datetimeString.substring(8, 10);
          //                   let hours = parseInt(datetimeString.substring(11, 13));
          //                   const minutes = parseInt(datetimeString.substring(14, 16));
          //                   const ampm = datetimeString.substring(16);

          //                   // Adjusting hours for AM/PM format
          //                   if (ampm === 'PM' && hours !== 12) {
          //                     hours += 12;
          //                   } else if (ampm === 'AM' && hours === 12) {
          //                     hours = 0;
          //                   }

          //                   // Creating the new Date object
          //                   let newDate = new Date(year, month, day, hours, minutes);
          //                   return {
          //                     id: it.ticket_id.split("+")[1] == "null" ? null : Number(it.ticket_id.split("+")[1]),
          //                     rq_schedule_datetime: `${newDate.getFullYear()}-${(newDate.getMonth() + 1).toString().padStart(2, '0')}-${newDate.getDate().toString().padStart(2, '0')} ${newDate.getHours().toString().padStart(2, '0')}:${newDate.getMinutes().toString().padStart(2, '0')}:${newDate.getSeconds().toString().padStart(2, '0')}`,
          //                     ticket_id: Number(it.ticket_id.split("+")[0]),
          //                     refund_status: false
          //                   };
          //                 } else {
          //                   return {
          //                     id: it.ticket_id.split("+")[1] == "null" ? null : Number(it.ticket_id.split("+")[1]),
          //                     rq_schedule_datetime: null,
          //                     ticket_id: Number(it.ticket_id.split("+")[0]),
          //                     refund_status: false
          //                   };
          //                 }
          //               })],
          //               subTotal: subItems[0].subtotal,
          //             };

          //             items.push(currentItem);

          //             apiUrls.push({
          //               url: `https://testing.thernloven.com/tickets-api-new/public/api/reservations/user-create/${subItems[0].reservation_id}`, items, stripe_token: stripeToken
          //             })
          //           }

          //           const apiRequests = apiUrls.map((urlItem: any) => axios.put(urlItem.url, { items: urlItem.items, stripe_token: urlItem.stripe_token }));
          //           const result = await Promise.all(apiRequests);

          //           if (result[0].status === 200) {
          //             setCart({
          //               adultInfo: [],
          //               childInfo: [],
          //             });
          //             localStorage.removeItem("CART_DATA");

          //             await Swal.fire({
          //               icon: "success",
          //               title: "Transaction",
          //               text: "Success! Your payment has been processed successfully.",
          //               confirmButtonText: "Go to My Bookings",
          //             });

          //             if (guestCheckout || localStorage.getItem("orderLoginNumber")) {
          //               // Update state variable with the response
          //               setOrderLookup({
          //                 orderLoginNumber: result[0].data.order_number,
          //                 orderLoginEmail: result[0].data.email,
          //                 phone: result[0].data.phone,
          //                 customer_name_kr: result[0].data.customer_name_kr,
          //                 customer_name_en: result[0].data.customer_name_en,
          //               });

          //               localStorage.setItem("orderLoginNumber", result[0].data.order_number);
          //               localStorage.setItem("orderLoginEmail", result[0].data.email);
          //               localStorage.setItem("phone", result[0].data.phone);
          //               localStorage.setItem("customer_name_en", result[0].data.customer_name_en);
          //               localStorage.setItem("customer_name_kr", result[0].data.customer_name_kr);

          //               // navigate to my boooking page directly
          //               navigate(
          //                 `/my-bookings?email=${result[0].data.email}&order_number=${result[0].data.order_number}`
          //               );
          //             } else {
          //               navigate("/my-bookings");
          //             }
          //           } else {
          //             // console.log("Error: " + result?.status);
          //             Swal.fire({
          //               icon: "warning",
          //               title: "Something wrong!",
          //               text: "Please try again!",
          //               confirmButtonText: "OK",
          //             });
          //           }
          //         } else {// Make the post request if new
          //           // Create the payload for the post request
          //           const payload = {
          //             payment_type: "Credit Card",
          //             fullname: formData.koreanFullName,
          //             first_name: formData.firstName,
          //             last_name: formData.lastName,
          //             email_confirmation: localStorage.getItem("orderLoginNumber") ? localStorage.getItem("orderLoginEmail") : formData.confirmEmail,
          //             departure_date: formData.departureDate || null,
          //             order_date: getTodayDate(),
          //             customer_name_kr: formData.koreanFullName || "Placeholder Korean Name",
          //             customer_name_en: `${formData.firstName} ${formData.lastName}`,
          //             email: localStorage.getItem("orderLoginNumber") ? localStorage.getItem("orderLoginEmail") : formData.email,
          //             phone: formData.phone,
          //             discount_amount: discount,
          //             stripe_token: stripeToken,
          //             items: cartData || [],
          //             vendor_comissions: [],
          //           };

          //           const { data: reservations, status } = await axios.post(
          //             `${API}/reservations/user-create`,
          //             payload,
          //           );

          //           if (status === 201) {
          //             // Handle the response (assuming it's successful)
          //             setCart({
          //               adultInfo: [],
          //               childInfo: [],
          //             });
          //             localStorage.removeItem("CART_DATA");

          //             await Swal.fire({
          //               icon: "success",
          //               title: "Transaction",
          //               text: "Success! Your payment has been processed successfully.",
          //               confirmButtonText: "Go to My Bookings",
          //             });

          //             if (guestCheckout || localStorage.getItem("orderLoginNumber")) {
          //               // Update state variable with the response
          //               setOrderLookup({
          //                 orderLoginNumber: reservations.order_number,
          //                 orderLoginEmail: reservations.email,
          //                 phone: reservations.phone,
          //                 customer_name_kr: reservations.customer_name_kr,
          //                 customer_name_en: reservations.customer_name_en,
          //               });

          //               localStorage.setItem("orderLoginNumber", reservations.order_number);
          //               localStorage.setItem("orderLoginEmail", reservations.email);
          //               localStorage.setItem("phone", reservations.phone);
          //               localStorage.setItem("customer_name_en", reservations.customer_name_en);
          //               localStorage.setItem("customer_name_kr", reservations.customer_name_kr);

          //               // navigate to my boooking page directly
          //               navigate(
          //                 `/my-bookings?email=${reservations.email}&order_number=${reservations.order_number}`
          //               );

          //               // Add user count to current ticket

          //             } else {
          //               navigate("/my-bookings");
          //             }
          //           } else {
          //             throw new Error("Opps, Something wrong");
          //           }
          //         }
          //       } catch (error: any) {
          //         // Handle the error
          //         const errorMessage =
          //           error?.response?.data?.message ||
          //           "An unexpected error occurred. Please try again later.";

          //         Swal.fire({
          //           icon: "error",
          //           title: "Oops...",
          //           text: errorMessage,
          //         });

          //         // Log the error for debugging
          //         console.log("Error:", error);
          //         console.log("response error", error?.response?.data);
          //       } finally {
          //         setHasSubmitted(false);
          //       }
          //     } else {
          //       console.log("error", error);

          //       Swal.fire({
          //         icon: "error",
          //         title: "Oops...",
          //         text: "Please input all billing information correctly!",
          //       }).then(() => {
          //         setHasSubmitted(false);
          //       });
          //     }
          //   }
          // }
          try {
            const result = await card.tokenize();
            stripeToken = result.token;
            if (result.status === "OK") {
              setHasSubmitted(true);

              let isEdit = checkIfBookingEdit(cart);
              // Parse the cart data
              const { data: cartData } = parseCartData(cart);

              if (isEdit) {
                let apiUrls: any = [];
                const combinedCartData = [...cart.adultInfo, ...cart.childInfo];
                // Get distinct reservation Ids
                const reservationIds = combinedCartData.map((obj: any) => obj.reservation_id);
                let distinctReservationIds: any = [];

                for (let i = 0; i < reservationIds.length; i++) {
                  if (distinctReservationIds.indexOf(reservationIds[i]) === -1) {
                    distinctReservationIds.push(reservationIds[i]);
                  }
                }

                for (let reservationId of distinctReservationIds) {
                  let items: any = [];
                  let subItems = combinedCartData.filter((it: any) => it.reservation_id === reservationId && it.subtotal !== -100);
                  let reservations = reservationsData.reservations;
                  let currentReservation: any = reservations[reservations.findIndex((it: any) => it.id === reservationId)];

                  let currentItemBeforeUpdate: any = currentReservation.reservation_items.filter((it: any) => it.id === subItems[0].item_id);
                  let otherItems = currentReservation.reservation_items.filter((it: any) => it.id !== subItems[0].item_id);
                  let otherItemsTosend = otherItems.map((data: any) => ({
                    id: data.id,
                    adult_child_type: data.adult_child_type,
                    child_age: data.child_age,
                    price: parseFloat(data.price) || 0,
                    quantity: parseInt(data.quantity),
                    ticket_sent_status: data.ticket_sent_status,
                    ticket_sent_date: data.ticket_sent_date,
                    refund_status: data.refund_status,
                    refund_sent_date: data.refund_sent_date,
                    reservation_id: parseFloat(data.reservation_id),
                    category_id: parseFloat(data.category_id),
                    subcategory_id: parseFloat(data.subcategory_id),
                    price_list_id: parseFloat(data.price_list_id),
                    sub_items:
                      data.reservation_sub_items?.map((subitem: any) => ({
                        id: subitem.id,
                        rq_schedule_datetime: subitem.rq_schedule_datetime,
                        ticket_id: subitem.ticket_id,
                        refund_status: subitem.refund_status,
                      })) || [],
                  }));

                  items.push(...otherItemsTosend);
                  console.log("subItems are", subItems);
                  let currentItem: any = {
                    id: subItems[0].item_id,
                    adult_child_type: subItems[0].adult_child_type,
                    child_age: null,

                    price: subItems[0].subCategoryPrice || subItems[0].price,
                    quantity: subItems[0].quantity,

                    ticket_sent_status: null,
                    ticket_sent_date: null,
                    refund_status: null,
                    refund_sent_date: null,

                    reservation_id: subItems[0].reservation_id,

                    category_id: subItems[0].categoryId ? parseFloat(subItems[0].categoryId) : null,
                    subcategory_id: subItems[0].subCategoryId,
                    price_list_id: subItems[0].priceOptionId ? parseFloat(subItems[0].priceOptionId) : null,

                    sub_items: [
                      ...subItems.map((it: any, index: number) => {
                        if (it.tour_date) {
                          const datetimeString = it.tour_date;

                          // Extracting components from the datetime string
                          const year = datetimeString.substring(0, 4);
                          const month = datetimeString.substring(5, 7) - 1; // Subtract 1 because months start from 0 (January is 0)
                          const day = datetimeString.substring(8, 10);
                          let hours = parseInt(datetimeString.substring(11, 13));
                          const minutes = parseInt(datetimeString.substring(14, 16));
                          const ampm = datetimeString.substring(16);

                          // Adjusting hours for AM/PM format
                          if (ampm === "PM" && hours !== 12) {
                            hours += 12;
                          } else if (ampm === "AM" && hours === 12) {
                            hours = 0;
                          }

                          // Creating the new Date object
                          let newDate = new Date(year, month, day, hours, minutes);
                          return {
                            id: it.ticket_id.split("+")[1] == "null" ? null : Number(it.ticket_id.split("+")[1]),
                            rq_schedule_datetime: `${newDate.getFullYear()}-${(newDate.getMonth() + 1).toString().padStart(2, "0")}-${newDate.getDate().toString().padStart(2, "0")} ${newDate
                              .getHours()
                              .toString()
                              .padStart(2, "0")}:${newDate.getMinutes().toString().padStart(2, "0")}:${newDate.getSeconds().toString().padStart(2, "0")}`,
                            ticket_id: Number(it.ticket_id.split("+")[0]),
                            refund_status: false,
                          };
                        } else {
                          return {
                            id: it.ticket_id.split("+")[1] == "null" ? null : Number(it.ticket_id.split("+")[1]),
                            rq_schedule_datetime: null,
                            ticket_id: Number(it.ticket_id.split("+")[0]),
                            refund_status: false,
                          };
                        }
                      }),
                    ],
                    subTotal: subItems[0].subtotal,
                  };

                  items.push(currentItem);

                  apiUrls.push({
                    url: `https://testing.thernloven.com/tickets-api-new/public/api/reservations/user-create/${subItems[0].reservation_id}`,
                    items,
                    stripe_token: stripeToken,
                  });
                }

                const apiRequests = apiUrls.map((urlItem: any) => axios.put(urlItem.url, { items: urlItem.items, stripe_token: urlItem.stripe_token }));
                const result = await Promise.all(apiRequests);

                if (result[0].status === 200) {
                  // Remove all items after payment
                  setCart({
                    adultInfo: [],
                    childInfo: [],
                  });
                  localStorage.removeItem("CART_DATA");

                  await Swal.fire({
                    icon: "success",
                    title: "Transaction",
                    text: "Success! Your payment has been processed successfully.",
                    confirmButtonText: (localStorage.getItem("role") === "1" || localStorage.getItem("role") === "2") ? "OK" : "Go to My Bookings",
                  });

                  // Only for users, navigating to My booking is allowed
                  if (!(localStorage.getItem("role") === "1" || localStorage.getItem("role") === "2")) {
                    if (guestCheckout || localStorage.getItem("orderLoginNumber")) {
                      // Update state variable with the response
                      setOrderLookup({
                        orderLoginNumber: result[0].data.order_number,
                        orderLoginEmail: result[0].data.email,
                        phone: result[0].data.phone,
                        customer_name_kr: result[0].data.customer_name_kr,
                        customer_name_en: result[0].data.customer_name_en,
                      });

                      localStorage.setItem("orderLoginNumber", result[0].data.order_number);
                      localStorage.setItem("orderLoginEmail", result[0].data.email);
                      localStorage.setItem("phone", result[0].data.phone);
                      localStorage.setItem("customer_name_en", result[0].data.customer_name_en);
                      localStorage.setItem("customer_name_kr", result[0].data.customer_name_kr);

                      // navigate to my boooking page directly
                      navigate(`/my-bookings?email=${result[0].data.email}&order_number=${result[0].data.order_number}`);
                    } else {
                      navigate("/my-bookings");
                    }
                  } else {
                    window.location.reload();
                  }
                } else {
                  // console.log("Error: " + result?.status);
                  Swal.fire({
                    icon: "warning",
                    title: "Something wrong!",
                    text: "Please try again!",
                    confirmButtonText: "OK",
                  });
                }
              } else {
                // Make the post request if new
                // Create the payload for the post request
                const payload = {
                  payment_type: "Credit Card",
                  fullname: formData.koreanFullName,
                  first_name: formData.firstName,
                  last_name: formData.lastName,
                  email_confirmation: localStorage.getItem("orderLoginNumber") ? localStorage.getItem("orderLoginEmail") : formData.confirmEmail,
                  departure_date: formData.departureDate || null,
                  order_date: getTodayDate(),
                  customer_name_kr: formData.koreanFullName || "Placeholder Korean Name",
                  customer_name_en: `${formData.firstName} ${formData.lastName}`,
                  email: localStorage.getItem("orderLoginNumber") ? localStorage.getItem("orderLoginEmail") : formData.email,
                  phone: formData.phone,
                  discount_amount: parseDiscount(discount),
                  stripe_token: stripeToken,
                  items: cartData || [],
                  vendor_comissions: [],
                };

                const token = localStorage.getItem("authToken");
                const headers = {
                  Authorization: `Bearer ${token}`,
                };

                const { data: reservations, status } = await axios.post(`${API}/reservations/user-create`, payload, { headers });

                if (status === 201) {
                  // Handle the response (assuming it's successful)
                  setCart({
                    adultInfo: [],
                    childInfo: [],
                  });
                  localStorage.removeItem("CART_DATA");

                  await Swal.fire({
                    icon: "success",
                    title: "Transaction",
                    text: "Success! Your payment has been processed successfully.",
                    confirmButtonText: (localStorage.getItem("role") === "1" || localStorage.getItem("role") === "2") ? "OK" : "Go to My Bookings",
                  });

                  // Only for users, navigating to My booking is allowed
                  if (!(localStorage.getItem("role") === "1" || localStorage.getItem("role") === "2")) {
                    if (guestCheckout || localStorage.getItem("orderLoginNumber")) {
                      // Update state variable with the response
                      setOrderLookup({
                        orderLoginNumber: reservations.order_number,
                        orderLoginEmail: reservations.email,
                        phone: reservations.phone,
                        customer_name_kr: reservations.customer_name_kr,
                        customer_name_en: reservations.customer_name_en,
                      });

                      localStorage.setItem("orderLoginNumber", reservations.order_number);
                      localStorage.setItem("orderLoginEmail", reservations.email);
                      localStorage.setItem("phone", reservations.phone);
                      localStorage.setItem("customer_name_en", reservations.customer_name_en);
                      localStorage.setItem("customer_name_kr", reservations.customer_name_kr);

                      // navigate to my boooking page directly
                      navigate(`/my-bookings?email=${reservations.email}&order_number=${reservations.order_number}`);

                      // Add user count to current ticket
                    } else {
                      navigate("/my-bookings");
                    }
                  } else {
                    window.location.reload();
                  }
                } else {
                  throw new Error("Opps, Something wrong");
                }
              }
            } else {
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Please input all billing information correctly!",
              }).then(() => {
                setHasSubmitted(false);
              });
            }
          } catch (error: any) {
            // Handle the error

            if (error?.response?.status === 422) {
              toast.error(error?.response?.data);
            } else {
              const errorMessage = error?.response?.data?.message || "An unexpected error occurred. Please try again later.";

              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: errorMessage,
              });
            }
          } finally {
            setHasSubmitted(false);
          }
        } else if (paymentType === "cash") {
          localStorage.setItem("customerInfo", JSON.stringify(getValues()));
          const token = localStorage.getItem("authToken");
          const headers = {
            Authorization: `Bearer ${token}`,
          };
          try {
            // Parse the cart data
            const { data: cartData } = parseCartData(cart);
            console.log("cartData is", cartData);
            // Make the post request if new
            // Create the payload for the post request
            const payload = {
              fullname: formData.koreanFullName,
              first_name: formData.firstName,
              last_name: formData.lastName,
              email_confirmation: formData.confirmEmail,
              departure_date: formData.departureDate || null,
              order_date: getTodayDate(),
              customer_name_kr: formData.koreanFullName || "Placeholder Korean Name",
              customer_name_en: `${formData.firstName} ${formData.lastName}`,
              email: formData.email,
              phone: formData.phone,
              discount_amount: parseDiscount(discount),
              payment_type: "Cash",
              credit: parseDiscount(credit),
              stripe_token: null,
              items: cartData || [],
              vendor_comissions: [],
            };

            const { data: reservations, status } = await axios.post(`${API}/reservations/user-create`, payload, { headers });
            console.log(status);

            if (status === 201) {
              // Handle the response (assuming it's successful)
              setCart({
                adultInfo: [],
                childInfo: [],
              });
              localStorage.removeItem("CART_DATA");

              await Swal.fire({
                icon: "success",
                title: "Transaction",
                text: "Success! Your payment has been processed successfully.",
                confirmButtonText: (localStorage.getItem("role") === "1" || localStorage.getItem("role") === "2") ? "OK" : "Go to My Bookings",
              });

              // if (guestCheckout || localStorage.getItem("orderLoginNumber")) {
              //   // Update state variable with the response
              //   setOrderLookup({
              //     orderLoginNumber: reservations.order_number,
              //     orderLoginEmail: reservations.email,
              //     phone: reservations.phone,
              //     customer_name_kr: reservations.customer_name_kr,
              //     customer_name_en: reservations.customer_name_en,
              //   });

              //   localStorage.setItem("orderLoginNumber", reservations.order_number);
              //   localStorage.setItem("orderLoginEmail", reservations.email);
              //   localStorage.setItem("phone", reservations.phone);
              //   localStorage.setItem("customer_name_en", reservations.customer_name_en);
              //   localStorage.setItem("customer_name_kr", reservations.customer_name_kr);

              //   // navigate to my boooking page directly
              //   navigate(
              //     `/my-bookings?email=${reservations.email}&order_number=${reservations.order_number}`
              //   );

              //   // Add user count to current ticket

              // } else {

              // }
              // Remove all items after payment
              setCart({
                adultInfo: [],
                childInfo: [],
              });
              localStorage.removeItem("CART_DATA");

              // Only for users, navigating to My booking is allowed
              if (!(localStorage.getItem("role") === "1" || localStorage.getItem("role") === "2")) {
                navigate("/my-bookings");
              } else {
                window.location.reload();
              }
            } else {
              throw new Error("Opps, Something wrong");
            }
          } catch (error: any) {
            // Handle the error

            if (error?.response?.status === 422) {
              toast.error(error?.response?.data);
            } else {
              const errorMessage = error?.response?.data?.message || "An unexpected error occurred. Please try again later.";

              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: errorMessage,
              });
            }
          } finally {
            setHasSubmitted(false);
          }
        }
      }
    }
  };

  if (isLoggedIn || guestCheckout || localStorage.getItem("orderLoginNumber")) {
    return (
      <section className="w-full mt-4 xl:mt-0">
        <div className="p-4 bg-white mb-5">
          {/* <SpaceY />
          <SpaceY /> */}
          <div className="mb-4">
            <span className="font-normal font-poppins text-darkGray">Contact Details</span>
          </div>
          {/* <SpaceY /> */}
          {/* <SpaceY /> */}
          <Controller
            name="koreanFullName"
            rules={{ required: true }}
            control={control}
            render={({ field }) => (
              <MainInput disabled={hasUserProfile} containerClassName="w-full" placeholder="Full Name (한국이름)" value={field.value} onChange={(value) => field.onChange(value)} />
            )}
          />
          <SpaceY />
          <Controller
            name="lastName"
            rules={{ required: true }}
            control={control}
            render={({ field }) => (
              <MainInput
                //   error={errors.confirmPassword?.message}
                disabled={hasUserProfile}
                containerClassName="w-full"
                value={field.value}
                placeholder="Last Name"
                onChange={(value) => field.onChange(value)}
              />
            )}
          />{" "}
          <SpaceY />
          <Controller
            name="firstName"
            rules={{ required: true }}
            control={control}
            render={({ field }) => (
              <MainInput
                //   error={errors.confirmPassword?.message}
                disabled={hasUserProfile}
                containerClassName="w-full"
                value={field.value}
                placeholder="First Name"
                onChange={(value) => field.onChange(value)}
              />
            )}
          />
          <SpaceY />
          <Controller
            name="email"
            rules={{ required: true }}
            control={control}
            render={({ field }) => (
              <MainInput containerClassName="w-full" error={errors.email?.message} disabled={hasUserProfile} value={field.value} placeholder="Email" onChange={(value) => field.onChange(value)} />
            )}
          />
          {errors.email && <p style={{ color: "red" }}>{errors.email.message}</p>}
          <SpaceY />
          <Controller
            name="confirmEmail"
            rules={{ required: true }}
            control={control}
            render={({ field }) => (
              <MainInput
                containerClassName="w-full"
                error={errors.confirmEmail?.message}
                disabled={hasUserProfile}
                value={field.value}
                placeholder="Retype Email"
                onChange={(value) => field.onChange(value)}
              />
            )}
          />{" "}
          {errors.confirmEmail && <p style={{ color: "red" }}>{errors.confirmEmail.message}</p>}
          <SpaceY />
          <Controller
            name="phone"
            rules={{ required: true }}
            control={control}
            render={({ field }) => (
              <PhoneInput
                containerClassName="w-full bg-white "
                //   error={errors.confirmPassword?.message}
                disabled={hasUserProfile}
                number={field.value}
                onChange={(value) => field.onChange(value)}
              />
            )}
          />
          <SpaceY />
          <div className="p-1 text-sm text-darkGray font-poppins">여행 예정일 (Optional)</div>
          <SpaceY />
          <Controller
            name="departureDate"
            rules={{ required: false }}
            control={control}
            render={({ field }) => <input className="w-full px-4 py-2 border border-gray text-darkGray font-poppins" value={field.value} type="date" onChange={(value) => field.onChange(value)} />}
          />{" "}
        </div>{" "}
        <SpaceY /> <SpaceY />
        <div className="p-4 bg-white text-darkGray font-poppins">
          {/* <SpaceY /> */}
          <div className="mb-4">
            {localStorage.getItem("role") === "1" || localStorage.getItem("role") === "2" ? (
              <FormControl>
                <RadioGroup aria-labelledby="demo-controlled-radio-buttons-group" name="controlled-radio-buttons-group" value={paymentType} onChange={(e) => handlePaymentChange(e.target.value)}>
                  <FormControlLabel value="credit_card" control={<Radio />} label="Credit Card Payment" />
                  <FormControlLabel value="cash" control={<Radio />} label="Cash Payment" />
                </RadioGroup>
              </FormControl>
            ) : (
              <span className="font-normal">Credit Card Information</span>
            )}
          </div>
          {/* <SpaceY /> <SpaceY /> */}
          {/* <hr className="hidden w-full border rounded md:block border-gray" /> */}
          {/* <SpaceY /> */}
          <div className="font-medium font-poppins text-darkGray mb-4" style={{ display: "flex", justifyContent: "space-between" }}>
            <span className="font-normal font-poppins text-darkGray">Grand Total:</span>

            <span className="font-normal font-poppins text-darkGray">{`$${(parseFloat(totalPrice) - parseDiscount(discount) - parseDiscount(credit)).toFixed(2)}`}</span>
          </div>
          {/* <SpaceY /> */}
          {/* <hr className="hidden w-full border rounded md:block border-gray mb-5" /> */}
          {/* <SpaceY /> */}
          {paymentType === "cash" ? (
            <div>
              <MainInput placeholder="Credit" value={credit === "" ? "" : `$${credit}`} onChange={(val) => handleCreditChange(val)} />
              <SpaceY />
              <MainInput placeholder="Debit" disabled value={"$" + (parseFloat(totalPrice) - parseDiscount(discount) - parseDiscount(credit)).toFixed(2)} onChange={(val) => { }} />
              <SpaceY />
            </div>
          ) : (
            <div>
              {/* <div className="w-full px-4 py-3 border border-gray">
                <CardElement className="text-darkGray font-poppins" options={{ hidePostalCode: true }} />
              </div> */}
              <div id="payment-form">
                <div id="card-container"></div>
              </div>
              {/* <SpaceY /> */}
            </div>
          )}
          {(localStorage.getItem("role") === "1" || localStorage.getItem("role") === "2") && (
            <div className="mb-4">
              <MainInput placeholder="Discount" value={discount === "" ? "" : `$${discount}`} onChange={(val) => handleChange(val)} />
            </div>
          )}
          {/* <SpaceY /> <SpaceY />
          <SpaceY /> <SpaceY /> */}
          <div className="flex justify-center gap-4">
            <MainButton
              containerClassName="font-normal font-poppins text-darkGray px-8 w-full"
              disabled={Boolean(Object.entries(errors).length) || totalPrice === 0 || hasSubmitted || Object.keys(errors).length > 0}
              text={hasSubmitted ? "Processing..." : "Pay"}
              onClick={handleSubmit(onSubmit)}
            />
          </div>
        </div>
      </section>
    );
  } else {
    return (
      <section className="w-full mt-5 xl:mt-0">
        <div className="p-4 bg-white text-darkGray font-poppins">
          <div className="font-medium font-poppins text-darkGray flex justify-center ">
            <div className="flex justify-between w-[13.1rem] py-[2rem]">
              <span className="font-normal font-poppins text-darkGray">Grand Total:</span>
              <span className="font-normal font-poppins text-darkGray">{`$${parseFloat(totalPrice).toFixed(2)}`}</span>
            </div>
          </div>
          {/* <SpaceY />
          <hr className="hidden w-full border rounded md:block border-gray mb-9" /> */}
          <div className="flex justify-center gap-4 font-normal font-poppins text-darkGray">
            <MainButton containerClassName="font-normal font-poppins text-darkGray px-4 w-full" disabled={totalPrice === 0} text="Proceed Checkout" onClick={() => navigate("/no-auth-checkout")} />
          </div>
        </div>
      </section>
    );
  }
}

export default Checkout;
