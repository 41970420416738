import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import jwtDecode from "jwt-decode";
import { useParams } from "react-router-dom";
import { staticFiles } from "../../shared";
import { MainButton } from "../../shared/components/Buttons";
import { SpaceY } from "../../shared/components/Utils";
import { TicketSelector } from "../package_tour/components/PackageBuyDetail";
import { useGetTicket } from "../../shared/hooks";
import { convertLink } from "../../shared/components/Utils";
export const ProductDetailView = () => {
  const [displayFilter, setDisplayFilter] = useState(false);
  const navigate = useNavigate();

  const checkTokenExpiration = () => {
    const token = localStorage.getItem("authToken");

    if (token) {
      const decodedToken: any = jwtDecode(token);

      const currentTime = Date.now() / 1000; // Convert current time to seconds

      if (decodedToken.exp < currentTime) {
        // Token has expired, force logout here
        // For example, clear the token from local storage and redirect the user to the login page
        localStorage.removeItem("authToken");
        localStorage.removeItem("loginData");
        localStorage.removeItem("order_number");
        localStorage.removeItem("useremail");

        navigate("/");
      }
    }
  };

  useEffect(() => {
    const intervalId = setInterval(checkTokenExpiration, 1000); // Check token expiration every second
    return () => clearInterval(intervalId); // Clear the interval when the component unmounts
  }, []);

  const productFakeData = {
    name: "엠파이어 스테이트 빌딩 전망대",
    tourDetail:
      "엠파이어 스테이트 빌딩은 세계에서 가장 유명한 건물로 뉴욕에 있는 상징적인 건물 입니다. 전망대에 오르면 뉴욕의 전경을 한 눈에 담을 수 있고, 날씨가 좋은 날에는 미국 동부의 6개 주를 보실 수 있습니다. 전망대 뿐만 아니라 엠파이어 스테이트 빌딩이 구상된 당시부터 지금까지의 스토리를 담은 박물관도 경험하실 수 있습니다. 관광객의 안전과 편리함을 최우선으로 생각하고 있는 엠파이어 스테이트 빌딩은 한국어가 포함된 9개의 언어로 설명을 들을 수 있는 애플리케이션을 제공하고 있으며, 건물 전체에 무료 와이파이도 제공할 수 있습니다. 매일매일 운영하고 있는 엠파이어 스테이트 빌딩 전망대는 뉴욕의 핵심 관광지인 타임스퀘어와 도보거리에 위치하고 있으며 빌딩 주변에는 메이시스 백화점, 메디슨 스퀘어 가든, 펜스테이션 등 쇼핑, 공연, 대중교통을 함께 이용할 수 있는 곳들이 있습니다.",
  };

  const { ticket } = useGetTicket();


  return (ticket && (
    <div className="flex w-full gap-x-8 flex-col md:flex-row md:relative mt-16">
      <div className="flex flex-col w-full md:w-2/3">
        {displayFilter ? (
          <TicketSelector ticket={ticket} />
        ) : (
          <>
            <div className="w-full mb-10 text-xl font-bold font-volkhov">
              {ticket.title_en}
            </div>
            
            <div
              dangerouslySetInnerHTML={{
                __html: convertLink(ticket?.ticket_content?.content || ""),
              }}
            ></div>
          </>
        )}
        <SpaceY />
        {/* <MainButton
          text={"티켓구입"}
          containerClassName="w-full block md:hidden"
          onClick={() => setDisplayFilter((prev) => !prev)}
        /> */}
      </div>
      <div className="md:w-1/3 md:block font-poppins w-full mt-8 md:mt-0">
        <div className="min-h-[500px] z-50">
          <TicketSelector ticket={ticket} />
        </div>
        <div className="w-[100%]">
          <img
            width={700}
            src={staticFiles.images.product_detail_traveler}
            className="relative object-contain left-[-40%]"
          />
        </div>
      </div>
    </div>
  ));
};
