import { ReactNode, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { API, staticFiles } from "../../../shared";
import { Logo } from "../../../shared/components/Logo";
import { NavBar, NavBarElement } from "../../../shared/components/NavBar";
import { SpaceY } from "../../../shared/components/Utils";
import { cityIdState, menuState, cityDataState, pageNumState } from "../../../App";
import { cartState, CartItem, orderLookupState } from "../../../App";
import { useGetAllCities } from "../../../shared/hooks";
import { useRef } from 'react';


const pageNavBar: NavBarElement[] = [
  // {
  //   name: "Home",
  //   path: "/main",
  // },
  {
    name: "NY Package Tour",
    path: "/package-tour",
    dropdownElements: [
      { name: "NY Big Apple Pass", subPath: "ba-pass", id: 97 },
      { name: "NY City Pass", subPath: "city-pass", id: 98 },
      { name: "NY City Explore Pass", subPath: "explore-pass", id: 99 },
      { name: "NY Big Apple Pass", subPath: "ba-pass", id: 97 },
      { name: "NY City Pass", subPath: "city-pass", id: 98 },
      { name: "NY City Explore Pass", subPath: "explore-pass", id: 99 },
    ],
  },
  {
    name: "NY City Attractions",
    path: "/city-attractions",
    dropdownElements: [
      { name: "NY Observation(Scenics)", subPath: "observations", id: 2 },
      { name: "NY Museum/Gallery", subPath: "museum-gallery", id: 104 },
      { name: "NY Rides/Cruises", subPath: "rides-cruises", id: 105 },
      { name: "NY Activities", subPath: "activities", id: 106 },
    ],
  },
  {
    name: "NY Guide Tour",
    path: "/guide-tour",
    dropdownElements: [
      { name: "NY Manhattan Day Tour", subPath: "manhattan-day", id: 4 },
      { name: "NY Manhattan Night Tour", subPath: "manhattan-night", id: 101 },
      { name: "NY Doson Tour", subPath: "doson-tour", id: 102 },
      { name: "NY UN Tour", subPath: "un-tour", id: 103 },
    ],
  },
  {
    name: "Musicals & Shows View",
    path: "/musicals_view",
  },
  {
    name: "여행정보",
    path: "/trip-info",
  },
  {
    name: "About Us",
    path: "/about",
  },
  {
    name: "Contact Us",
    path: "/contact",
  },
  {
    name: "My acoount",
    path: "",
    dropdownElements: [
      { name: "My Bookings", subPath: "my-bookings" },
      { name: "Account", subPath: "user/my-account" },
    ],
  },
  {
    name: "Sign out",
    path: "/",
  },
];

const pageNavBarNonMember: NavBarElement[] = [
  // {
  //   name: "Home",
  //   path: "/main",
  // },
  {
    name: "NY Package Tour",
    path: "/package-tour",
    dropdownElements: [
      { name: "NY Big Apple Pass", subPath: "ba-pass", id: 97 },
      { name: "NY City Pass", subPath: "city-pass", id: 98 },
      { name: "NY City Explore Pass", subPath: "explore-pass", id: 99 },
    ],
  },
  {
    name: "NY City Attractions",
    path: "/city-attractions",
    dropdownElements: [
      { name: "NY Observation(Scenics)", subPath: "observations", id: 2 },
      { name: "NY Museum/Gallery", subPath: "museum-gallery", id: 104 },
      { name: "NY Rides/Cruises", subPath: "rides-cruises", id: 105 },
      { name: "NY Activities", subPath: "activities", id: 106 },
    ],
  },
  {
    name: "NY Guide Tour",
    path: "/guide-tour",
    dropdownElements: [
      { name: "NY Manhattan Day Tour", subPath: "manhattan-day", id: 4 },
      { name: "NY Manhattan Night Tour", subPath: "manhattan-night", id: 101 },
      { name: "NY Doson Tour", subPath: "doson-tour", id: 102 },
      { name: "NY UN Tour", subPath: "un-tour", id: 103 },
    ],
  },
  {
    name: "Musicals & Shows View",
    path: "/musicals_view",
  },
  {
    name: "여행정보",
    path: "/trip-info",
  },
  {
    name: "About Us",
    path: "/about",
  },
  {
    name: "Contact Us",
    path: "/contact",
  },
  {
    name: "My acoount",
    path: "",
    dropdownElements: [{ name: "My Bookings", subPath: "my-bookings" }],
  },
  {
    name: "Sign out",
    path: "/",
  },
];

const pageNavBarNotSigned: NavBarElement[] = [
  // {
  //   name: "Home",
  //   path: "/main",
  // },
  {
    name: "Package Tour",
    path: "/package-tour",
    dropdownElements: [
      { name: "NY Big Apple Pass", subPath: "ba-pass" },
      { name: "NY City Pass", subPath: "city-pass" },
      { name: "NY City Explore Pass", subPath: "explore-pass" },
    ],
  },
  {
    name: "City Attractions",
    path: "/city-attractions",
    dropdownElements: [
      { name: "Observation(Scenics)", subPath: "observations" },
      { name: "Museum/Gallery", subPath: "museum-gallery" },
      { name: "Rides/Cruises", subPath: "rides-cruises" },
      { name: "Activities", subPath: "activities" },
    ],
  },
  {
    name: "Guide Tour",
    path: "/guide-tour",
    dropdownElements: [
      { name: "Manhattan Day Tour", subPath: "manhattan-day" },
      { name: "Manhattan Night Tour", subPath: "manhattan-night" },
      { name: "Doson Tour", subPath: "doson-tour" },
      { name: "UN Tour", subPath: "un-tour" },
    ],
  },
  {
    name: "Musicals & Shows View",
    path: "/musicals_view",
  },
  {
    name: "여행정보",
    path: "/trip-info",
  },
  {
    name: "About Us",
    path: "/about",
  },
  {
    name: "Contact Us",
    path: "/contact",
  },
  {
    name: "Login",
    path: "/user/log-in",
  },
  {
    name: "Register",
    path: "/user/sign-up",
  },
  {
    name: "Sign out",
    path: "/",
  },
];

// const pageLayoutNavBar: NavBarElement[] = [
//   {
//     name: "Home",
//     path: "/main",
//   },
//   {
//     name: "Package Tour",
//     path: "/package-tour",
//     dropdownElements: [
//       { name: "NY Big Apple Pass", subPath: "ba-pass", id: 97 },
//       { name: "NY City Pass", subPath: "city-pass", id: 98 },
//       { name: "NY City Explore Pass", subPath: "explore-pass", id: 99 },
//     ],
//   },
//   {
//     name: "City Attractions",
//     path: "/city-attractions",
//     dropdownElements: [
//       { name: "Observation(Scenics)", subPath: "observations", id: 2 },
//       { name: "Museum/Gallery", subPath: "museum-gallery", id: 104 },
//       { name: "Rides/Cruises", subPath: "rides-cruises", id: 105 },
//       { name: "Activities", subPath: "activities", id: 106 },
//     ],
//   },
//   {
//     name: "Guide Tour",
//     path: "/guide-tour",
//     dropdownElements: [
//       { name: "Manhattan Day Tour", subPath: "manhattan-day", id: 4 },
//       { name: "Manhattan Night Tour", subPath: "manhattan-night", id: 101 },
//       { name: "Doson Tour", subPath: "doson-tour", id: 102 },
//       { name: "UN Tour", subPath: "un-tour", id: 103 },
//     ],
//   },
//   {
//     name: "Musicals & Shows",
//     path: "/musicals-and-shows",
//   },
//   {
//     name: "여행정보",
//     path: "/main",
//   },
//   {
//     name: "About Us",
//     path: "/about",
//   },
//   {
//     name: "Contact Us",
//     path: "/contact",
//   },
// ];

const IconButton: React.FC<{
  icon: string;
  route?: string;
  name?: string;
  isOpen?: any;
  orderLoginData?: boolean;
  children?: ReactNode;
  badge?: number;
  handleClick?: any;
  order?: string;
}> = ({ icon, name, route, isOpen, orderLoginData, children, badge, handleClick, order }) => {
  const navigate = useNavigate();
  const classConst = orderLoginData
    ? "absolute flex flex-col  bg-[#2A353D]  text-white z-[100]  font-poppins xl:left-[79%] left-0 top-[3.1rem]  xl:top-[3.5rem] py-5 w-[200px] rounded-b opacity-[95%]"  
    : "absolute flex flex-col  bg-[#2A353D]  text-white z-[100]  font-poppins xl:left-[79%] left-0 top-[3.1rem]  xl:top-[3.5rem] py-5 w-[200px] rounded-b opacity-[95%]";

  const [isDropdownOpen, setDropdownOpen] = useState(false);

  const dropdownRef = useRef<HTMLDivElement>(null);

  const handleBlur = (event: React.FocusEvent<HTMLDivElement>) => {
    // Check if the related target (the new focused element) is outside of the dropdown
    if (dropdownRef.current && !dropdownRef.current.contains(event.relatedTarget as Node)) {
      setDropdownOpen(false);
    }
  };
  if (!!order) {
    if (children)
      return (
        <div
          onClick={() => {
            handleClick(order);
            setDropdownOpen(!isDropdownOpen); // Toggle dropdown on click
          }}
          onBlur={handleBlur}
          tabIndex={0} // Make this div focusable
          className="md:px-8 py-2 font-poppins text-sm rounded-sm text-dark flex justify-center items-center whitespace-nowrap"
        >
          <img className="cursor-pointer" src={icon} width="17" alt={icon} />
          {name && <span className="ml-1 cursor-pointer">{name}</span>}
          {isDropdownOpen && <div ref={dropdownRef} className={classConst}>{children}</div>}
        </div>
      );
  }


  return (
    <button onClick={() => route && navigate(route)} className="md:px-8 py-2 font-poppins text-sm rounded-sm text-dark flex justify-center items-center whitespace-nowrap">
      <img src={icon} width="17" alt={icon} />
      {name && <span className="ml-1">{name}</span>}

      {Boolean(badge) && (
        <div className="-mt-4 static inline-flex items-center justify-center w-5 h-5 xl:w-6 xl:h-6 text-xs font-bold text-white rounded-full bg-red top-2rem dark:border-gray-900">{badge}</div>
      )}
    </button>
  );
};

const getTotalShoppingCartCount = ({ childInfo, adultInfo }: { childInfo: CartItem[]; adultInfo: CartItem[] }) => {
  let quantityByCartId = new Map();
  const combinedInfo = [...childInfo, ...adultInfo];

  combinedInfo.forEach((info) => {
    if (info.subtotal != -100) {
      if (!quantityByCartId.has(info.cartId)) {
        quantityByCartId.set(info.cartId, info.quantity);
      }
    }
  });

  return Array.from(quantityByCartId.values())?.reduce((acc: number, curr: number) => acc + curr, 0);
};

export const Top = () => {
  const navigate = useNavigate();
  const [cart, setCart] = cartState.useState();
  const [orderLookup, setOrderLookup] = orderLookupState.useState();
  const [cartCount, setCartCount] = useState(getTotalShoppingCartCount(cart));
  const [isOpen, setIsOpen] = useState({ first: false, second: false });

  const handleClick = (order: string) => {
    if (order === "first") {
      setIsOpen({
        ...isOpen,
        first: !isOpen.first,
        second: false,
      });
    } else {
      setIsOpen({
        ...isOpen,
        second: !isOpen.second,
        first: false,
      });
    }
  };
  useEffect(() => {
    // This effect will be triggered whenever the `cart` state changes
    setCartCount(getTotalShoppingCartCount(cart));
  }, [cart]);

  const handleSignOut = () => {
    localStorage.removeItem("authToken");
    localStorage.removeItem("loginData");
    localStorage.removeItem("order_number");
    localStorage.removeItem("useremail");
    localStorage.removeItem("role");
    // Remove non Member loginData
    localStorage.removeItem("orderLoginNumber");
    localStorage.removeItem("orderLoginEmail");
    localStorage.removeItem("phone");
    localStorage.removeItem("customer_name_en");
    localStorage.removeItem("customer_name_kr");

    setOrderLookup({
      orderLoginNumber: "",
      orderLoginEmail: "",
      phone: "",
      customer_name_kr: "",
      customer_name_en: "",
    });

    // Remove Booking Edit items in Cart
    let cartData = localStorage.getItem("CART_DATA") ? JSON.parse(localStorage.getItem("CART_DATA") || "") : { adultInfo: [], childInfo: [] };

    cartData.adultInfo = cartData.adultInfo.filter((item: any) => item.reservation_id == null);

    cartData.childInfo = cartData.childInfo.filter((item: any) => item.reservation_id == null);

    localStorage.setItem("CART_DATA", JSON.stringify(cartData));
    cartData = JSON.parse(localStorage.getItem("CART_DATA") || "");

    setCart({
      adultInfo: cartData.adultInfo,
      childInfo: cartData.childInfo,
    });

    setCartCount(getTotalShoppingCartCount({ adultInfo: cartData.adultInfo, childInfo: cartData.childInfo }));

    console.log("cache file--------");
    if ("caches" in window) {
      caches.keys().then((names) => {
        // Delete all the cache files
        names.forEach((name) => {
          caches.delete(name);
        });
      });
    }
    navigate("/");
  };
  const authToken = localStorage.getItem("authToken");
  const loginData = localStorage.getItem("loginData");
  const orderLoginData = localStorage.getItem("orderLoginNumber") ? true : false;

  const loginState = (authToken && loginData) || orderLoginData;
  const { CitiesData } = useGetAllCities();
  const [menu, setMenu] = menuState.useState();
  const [totalNum, setTotalNum] = pageNumState.useState();
  let pageNavBar = [
    ...menu,
    {
      name: "My acoount",
      path: "",
      dropdownElements: [
        { name: "My Bookings", subPath: "my-bookings" },
        { name: "Account", subPath: "user/my-account" },
      ],
    },
    {
      name: "Sign out",
      path: "/",
    },
  ];
  const [cityId, setCityId] = cityIdState.useState();
  const [cityData, setCityData] = cityDataState.useState();

  useEffect(() => {
    if (CitiesData) {
      setCityData({ cityData: CitiesData });
    }
  }, [CitiesData]);

  useEffect(() => {
    fetch(`${API}/categories?city_id=${Number(cityId)}`)
      .then((res) => res.json())
      .then((data) => {
        let datas = data;
        let menuData = JSON.parse(JSON.stringify(menu));
        datas.forEach((obj: any, index: number) => {
          if (cityId === 36 && index === 4) return;
          if (obj.subcategories && obj.subcategories.length !== 0) {
            obj.subcategories.forEach((item: any, idx: number) => {
              if (!!menuData) {
                let dEle = menuData[index].dropdownElements;
                if (!!dEle) {
                  dEle[idx].name = item.name;
                  dEle[idx].id = Number(item.id);
                  dEle[idx].category_id = Number(item.category_id);
                }
              }
            });
          }
          if (!!menuData) {
            menuData[index].name = obj.name;
          }
        });
        setMenu(menuData);
        localStorage.setItem("cityId", String(cityId));
        setTotalNum(5);
      })
      .catch((error) => {
        console.log("Error in Top.tsx for fetching:", error);
      });
  }, [cityId]);
  return (
    <>
      <div className="z-[110] flex xl:hidden  justify-between md:justify-between items-center  px-[5vw] max-w-[1300px] w-full ">
        <IconButton isOpen={isOpen} order={"first"} handleClick={handleClick} icon={staticFiles.icons.menu} orderLoginData={orderLoginData}>
          {loginState ? (
            <NavBar elements={orderLoginData ? pageNavBarNonMember : pageNavBar} isMobile handleSignOut={handleSignOut} />
          ) : (
            <NavBar elements={pageNavBarNotSigned} isMobile handleSignOut={handleSignOut} />
          )}
        </IconButton>
        <div className="flex items-center justify-center">
          <Logo />
        </div>
        <IconButton badge={cartCount} route="/cart" icon={staticFiles.icons.cart} />
      </div>

      <div className="hidden xl:flex justify-center items-center px-[5vw] max-w-[1300px] mb-4">
        <div className="flex items-center justify-center">
          <Logo />
        </div>
        <div className="pt-8">
          <div className="flex justify-end text-sm font-poppins mb-4 relative">
            <IconButton badge={cartCount} route="/cart" icon={staticFiles.icons.cart} name="Cart" />

            {loginState ? (
              <>
                <IconButton isOpen={isOpen} order={"first"} handleClick={handleClick} icon={staticFiles.icons.sign_up} orderLoginData={orderLoginData}>
                  <>
                    <div className="flex justify-center font-medium text-white text-start p-4">MY ACCOUNT</div>
                    <SpaceY />
                    <button className="flex  py-4 gap-x-3 text-white font-sm  hover:bg-lightBlue/[.1] cursor-pointer px-8" onClick={() => navigate("/my-bookings")}>
                      <img alt="" src={staticFiles.icons.disposition} />
                      <span>My Bookings</span>
                    </button>
                    {!orderLoginData && (
                      <button className="flex py-4 gap-x-3 text-white font-sm  hover:bg-lightBlue/[.1] cursor-pointer px-8" onClick={() => navigate("/user/my-account")}>
                        <img alt="" src={staticFiles.icons.edit} />
                        <span>My Account</span>
                      </button>
                    )}
                    <button className="flex py-4 gap-x-3 text-white font-sm  hover:bg-lightBlue/[.1] cursor-pointer px-8" onClick={handleSignOut}>
                      <img alt="" src={staticFiles.icons.sign_out} />
                      <span>Sign Out</span>
                    </button>
                  </>
                </IconButton>
                <IconButton isOpen={isOpen} order={"second"} handleClick={handleClick} icon={staticFiles.icons.world} name={localStorage.getItem("cityName") || "New York"}>
                  {CitiesData?.map((value, index) => {
                    return (
                      <>
                        <button
                          key={index}
                          className="flex justify-center px-2 py-4 font-sm hover:bg-lightBlue/[.1] cursor-pointer"
                          onClick={() => {
                            setCityId(value.id);
                            localStorage.setItem("cityId", String(value.id));
                            localStorage.setItem("cityName", value.name);
                            // window.location.reload()

                            // Navigate to Home Page
                            navigate("/main");
                          }}
                        >
                          <span>{value.name}</span>
                        </button>
                      </>
                    );
                  })}
                </IconButton>
              </>
            ) : (
              <>
                <IconButton route="/user/log-in" icon={staticFiles.icons.lock} name="Login" />
                <IconButton route="/user/sign-up" icon={staticFiles.icons.sign_up} name="Sign Up" />
                <IconButton isOpen={isOpen} order={"second"} handleClick={handleClick} icon={staticFiles.icons.world} name={localStorage.getItem("cityName") || "New York"}>
                  <>
                    {CitiesData?.map((value, index) => {
                      return (
                        <>
                          <SpaceY />
                          <button
                            key={index}
                            className="flex justify-center px-2 py-4 font-sm hover:bg-lightBlue/[.1] cursor-pointer"
                            onClick={() => {
                              setCityId(value.id);
                              localStorage.setItem("cityId", String(value.id));
                              localStorage.setItem("cityName", value.name);
                              // window.location.reload()

                              // Navigate to Home Page
                              navigate("/main");
                            }}
                          >
                            <span>{value.name}</span>
                          </button>
                        </>
                      );
                    })}
                  </>
                </IconButton>
              </>
            )}
          </div>
          {/* <NavBar elements={category} /> */}
          <NavBar elements={menu} />
        </div>
      </div>
    </>
  );
};
