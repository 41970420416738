import { staticFiles } from "../../../shared";
import { Logo } from "../../../shared/components/Logo";
import { Link } from "react-router-dom";

const FooterCol: React.FC<{ children: React.ReactNode; hidden?: boolean }> = ({
  children,
  hidden,
}) => {
  const hiddenColClass = "w-1/3 grow flex flex-col justify-center px-[20px] items-center";
  const colClass = "w-1/3 grow flex flex-col justify-center px-[20px] items-center";
  return <div className={hidden ? hiddenColClass : colClass}>{children}</div>;
};

const fakeDestinationsLink = [
  "Return Policy",
  "Bullet list goes here",
  "Linked Text Goes here",
  "Linked Text Goes here",
  "Linked Text Goes here",
  "Linked Text Goes here",
  "Linked Text Goes here",
  "Linked Text Goes here",
  "Linked Text Goes here",
];

export const Footer = () => {
  return (
    <div className="h-[350px] w-full bg-[#262424] flex flex-col items-center">
      <div className="flex justify-between max-w-[1300px] h-[80%] gap-40">
        <FooterCol>
            <div className="min-h-[200px] flex flex-col justify-start ">
              <div className="flex justify-center">
                <Logo whiteText />
              </div>

              <div className="flex justify-center items-center mt-10 text-white font-poppins text-xs w-[200px]">
                <span className="inline-block">
                  #1 customer service trave agency in New York. Explore New York city with TAMICE Your
                  satisfaction is our high priority
                </span>
              </div>
          </div>
        </FooterCol>
        <FooterCol>
          <div className="min-h-[200px]">
          {/* <div className="min-h-[200px] hidden sm:block"> */}
            <span className="mb-5 text-sm font-medium text-white font-poppins">
              TOP DESTINATIONS
            </span>
            <div className="flex flex-col">
              {fakeDestinationsLink.map((item, idx) => (
                <div
                  key={item + idx}
                  className="text-xs text-white font-poppins hover:underline hover:cursor-pointer"
                >
                  {item === "Return Policy" ? (
                    <Link to="/return-policy">• {item}</Link>
                  ) : (
                    <span>• {item}</span>
                  )}
                </div>
              ))}
            </div>
          </div>
        </FooterCol>
        <FooterCol>
          <div className="min-h-[200px]">
            <div className="text-sm font-medium text-white font-poppins">CONTACT INFO</div>
            <div className="text-xs font-normal text-white font-poppins">
              Address: 151 West 46th Street, Suite 1002, New York, NY 10036
            </div>
            <div className="mt-3 text-xs font-normal text-white font-poppins">Phone:</div>
            <div className="text-xs font-normal text-white font-poppins">646-684-4848</div>
            <div className="text-xs font-normal text-white font-poppins">1800-6991</div>
            <div className="mt-3 text-xs font-normal text-white font-poppins">
              Contact: service@tamice.com
            </div>
            <div className="flex mt-3">
              <button onClick={() => { }}>
                <img src={staticFiles.icons.facebook} alt="fb" />
              </button>
              <button className="ml-2" onClick={() => { }}>
                <img src={staticFiles.icons.instagram} alt="ig" />
              </button>
              <button className="ml-2" onClick={() => { }}>
                <img src={staticFiles.icons.blog} alt="blog" />
              </button>
            </div>
          </div>
        </FooterCol>
      </div>
      <div className="flex justify-center items-center h-[20%] bg-black w-full">
        <span className="font-poppins text-[#A4A5A8] font-normal text -sm">
          COPYRIGHT 2022 TAMICE INC, ALL RIGHT RESERVED
        </span>
      </div>
    </div>
  );
};
